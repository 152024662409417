import { formatToBrazilianDate } from '@interco/lib-util/'

export const convertStrToDate = (strTimestamp: string) =>
  formatToBrazilianDate(new Date(strTimestamp))

export const subtractYearsFromDate = (date: Date, years: number) =>
  date.setFullYear(date.getFullYear() - years)

export const getDayMonth = (strTimestamp: string) => {
  const date = new Date(strTimestamp)

  return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}`
}
