import styled from 'styled-components'

export const Container = styled.div`
  height: calc(100vh - 56px - 96px);
  overflow: scroll;
`

export const Hero = styled.figure`
  img {
    width: 100%;
    height: auto;
  }
`

export const Header = styled.div`
  margin-bottom: 24px;
  padding: 0 24px;

  > :first-child {
    margin-bottom: 16px;
    text-align: center;
  }
`

export const Location = styled.div`
  margin-bottom: 24px;
  padding: 0 24px;
`

export const Coparticipation = styled.div`
  padding: 0 24px;

  > :first-child {
    margin-bottom: 24px;
  }

  span {
    display: block;

    &:first-child {
      margin-bottom: 8px;
    }
  }

  button {
    margin-top: 8px;
    padding: 0;
  }
`

export const PartnerClinics = styled.div`
  padding: 0 24px;

  > :first-child {
    margin-bottom: 8px;
  }
`

export const Works = styled.div`
  padding: 0 24px;

  > :first-child {
    margin-bottom: 16px;
  }
`

export const Footer = styled.div`
  padding: 24px;
  border: 1px solid var(--gray100);
`

export const BottomSheetContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  h3 {
    margin: 40px 0 16px;
  }

  button {
    margin-top: 16px;
  }
`
