import styled from 'styled-components'

export const Header = styled.div`
  padding: 24px 24px 0;

  h2 {
    margin: 24px 0 8px;
  }
`

export const Step = styled.div`
  span {
    display: block;

    &:first-child {
      margin-bottom: 4px;
    }
  }
`

export const Insurance = styled.div`
  padding: 0 24px;
`

export const InsuranceImage = styled.figure`
  margin-top: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 12px var(--gray200);
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin: 24px 0;
  padding: 0 24px;
`
