import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 24px;
  padding: 0 24px;

  h2 {
    margin: 24px 0 8px;
  }
`
export const Step = styled.div`
  span {
    display: block;

    &:first-child {
      margin-bottom: 4px;
    }
  }
`
