import { useCallback } from 'react'

import NewRelicUtils from 'config/monitoring/NewRelicUtils'
import { defaultError } from 'mocks/ResponseError'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { ErrorDetails } from 'store/error/types'

export const useLocalErrorHandler = () => {
  const handlerError = useCallback(
    (error: Error, errorCodeRef: string, errorDetails?: ErrorDetails, restart?: boolean) => {
      NewRelicUtils.noticeError(error, { errorCodeRef })

      if (errorDetails === undefined) {
        errorDetails = {
          ...defaultError,
          route: restart ? TypesRoutes.HOME : undefined,
        }
      }

      useBoundState.getState().setHasError(errorDetails)
      useBoundState.getState().setIsLoading(false)
    },
    [],
  )

  return handlerError
}
