import BaseService from 'config/bridge/BaseService'
import { IFaqResponse } from 'types/IFaq'

export default class FaqService {
  static async getFaq() {
    const response = await BaseService.doExecute<IFaqResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_PET}/faq`,
    })

    return response.data
  }
}
