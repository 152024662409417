import { EligibilityStatusEnum } from 'enums/EligibilityStatus'
import ValidationService from 'services/ValidationService'
import useBoundState from 'store'
import { errorHandling } from 'store/utils/provider'
import { StateCreator } from 'zustand'

import { EligibilityState } from './types'

export const createEligibilitySlice: StateCreator<EligibilityState, [], [], EligibilityState> = (
  set,
) => ({
  eligibility: EligibilityStatusEnum.ELIGIBLE,

  setEligibility: (eligibility) => set(() => ({ eligibility })),

  getEligibility: async () => {
    useBoundState.getState().setIsLoading(true)

    await ValidationService.getEligibility()
      .then((response) => {
        set({ eligibility: response.eligibility })
      })

      .catch((error) => {
        errorHandling(error as Error, 'ValidationService.getEligibility')
      })

    useBoundState.getState().setIsLoading(false)
  },
})
