import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 24px;
`

export const Faq = styled.div`
  padding: 0 24px;

  > :first-child {
    margin-top: 16px;
  }

  button {
    width: 100%;
    margin-top: 16px;
    padding: 0;

    &:hover:enabled {
      background-color: var(--neutral-theme);
    }
  }
`
export const Manual = styled.div`
  margin-bottom: 32px;
  padding: 0 24px;

  > :first-child {
    margin-bottom: 16px;
  }
`
export const Footer = styled.div`
  padding: 0 24px;

  button {
    width: 100%;
    margin-bottom: 16px;
  }
`
