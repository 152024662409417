import { TagEventsEnum } from 'enums/TagEvents'
import { useHistory } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { IPLanDetails } from 'store/subscription/types'
import { IPlansProps } from 'types/IHome'

import { uuid } from '@interco/inter-ui'
import { Button } from '@interco/inter-ui/components/Button'
import { Carousel } from '@interco/inter-ui/components/Carousel'
import { Text } from '@interco/inter-ui/components/Text'
import { formatToBRL } from '@interco/lib-util'

import { AnalyticsHomeClick } from '../Home.tags'
import * as S from './Plans.styles'

export const Plans = ({ plans }: IPlansProps) => {
  const history = useHistory()

  const setPlanDetails = useBoundState((state) => state.setPlanDetails)

  const handlePlanDetails = (planDetails: IPLanDetails) => {
    AnalyticsHomeClick(TagEventsEnum.PET_FLOW, {
      ref_figma: '3',
      action_id: 'Saiba mais',
      plan_type: planDetails.title,
    })

    setPlanDetails(planDetails)
    history.push(TypesRoutes.PLAN_DETAILS)
  }

  return (
    <S.Plans>
      <Text variant="headline-h2" as="h2" semiBold>
        Conheça os planos e coberturas
      </Text>

      <S.Slider>
        <Carousel
          dots
          arrows={false}
          infinite={false}
          speed={800}
          slidesToShow={1}
          dotsType="medium"
          verticalMode={false}
          centerMode
          centerPadding="24px"
          className="slider"
        >
          {plans.map((plan) => (
            <S.SliderContent key={plan.id}>
              <Text variant="body-2" bold colorWeight={500}>
                {plan.name}
              </Text>
              <Text variant="headline-h2" as="h2" semiBold>
                {formatToBRL(plan.price)}
                <strong>/mês</strong>
              </Text>

              <S.Coverages>
                {plan.coverages.map((coverage) => (
                  <Text key={uuid()} variant="body-3" as="li" colorWeight={500}>
                    {coverage.title}
                  </Text>
                ))}
              </S.Coverages>

              {plan.more && (
                <Button variant="link" onClick={() => handlePlanDetails(plan.more)}>
                  Saiba mais
                </Button>
              )}
            </S.SliderContent>
          ))}
        </Carousel>
      </S.Slider>
    </S.Plans>
  )
}
