import { Separator } from 'components'
import { ReceiptStatusEnum } from 'enums/ReceiptStatus'
import useBoundState from 'store'
import { AlertSign, SuccessSign } from 'styles/commons'
import { Icons } from 'utils/icons'

import { Flex } from '@interco/inter-ui/components/Flex'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './ReceiptStatus.styles'

export const ReceiptStatus = () => {
  const { status } = useBoundState((state) => state.receipt)

  const statusIcon = {
    success: <Icons.CheckCircle width="32px" height="32px" color="var(--neutral-theme)" />,
    pending: <Icons.Pending width="32px" height="32px" color="var(--neutral-theme)" />,
    email: <Icons.Email width="24px" height="24px" color="var(--gray500)" />,
  }

  const renderSteps = (
    <Flex direction="row" gap="16px">
      <div>{statusIcon.email}</div>

      <S.Step>
        <Text variant="body-3" bold colorWeight={500}>
          Passo a passo para começar a usar
        </Text>
        <Text variant="body-3">
          {status === ReceiptStatusEnum.ACTIVE
            ? 'Você receberá no seu e-mail todas as informações necessárias para começar a utilizar.'
            : 'Quando a contratação for concluída, você receberá no seu e-mail todas as informações necessárias para começar a utilizar.'}
        </Text>
      </S.Step>
    </Flex>
  )

  const renderPartnerProcess = (
    <>
      <AlertSign>{statusIcon.success}</AlertSign>
      <Text variant="headline-h2" as="h2" semiBold>
        O plano de saúde do seu pet está em análise!
      </Text>
      <Text variant="body-3">
        Avisaremos quando a contratação estiver ativa com as carências dos serviços e procedimentos
        iniciadas.
      </Text>

      <Separator borderWidth="1px" type="dashed" color="var(--gray200)" margin="24px 0" />

      {renderSteps}
    </>
  )

  const renderSuccess = (
    <>
      <SuccessSign>{statusIcon.success}</SuccessSign>
      <Text variant="headline-h2" as="h2" semiBold>
        O plano de saúde do seu pet já está ativo!
      </Text>
      <Text variant="body-3">
        Agora, todas as carências dos serviços e procedimentos já começam a contar!
      </Text>

      <Separator borderWidth="1px" type="dashed" color="var(--gray200)" margin="24px 0" />

      {renderSteps}
    </>
  )

  const chooseStatus: Record<ReceiptStatusEnum, JSX.Element> = {
    ACTIVE: renderSuccess,
    PARTNER_IN_PROCESS: renderPartnerProcess,
  }

  return <S.Container>{chooseStatus[status]}</S.Container>
}
