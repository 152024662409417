/**
 * Títulos disponíveis na aplicação.
 */
export enum PageTitles {
  EMPTY = '',
  PET = 'Plano Pet',
  FAQ = 'Perguntas frequentes',
  CLINICS = 'Rede Credenciada',
  DETAILS = 'Detalhes do plano',
}
