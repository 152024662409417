import BaseBridge from 'config/bridge/BaseBridge'
import { TagEventsEnum } from 'enums/TagEvents'

export const AnalyticsReceiptOpen = (name: string, params: Record<string, string>) => {
  BaseBridge.requestAnalytics(name, {
    ...params,
    ref_figma: '12',
    action_id: 'Visualizou o comprovante',
    ref_type: TagEventsEnum.REF_SCREEN,
    content_action: TagEventsEnum.ACTION_LOAD_DATA,
    screen: 'Comprovante',
  })
}

export const AnalyticsReceiptClick = (name: string, params: Record<string, string>) => {
  BaseBridge.requestAnalytics(name, {
    ...params,
    ref_type: TagEventsEnum.REF_BUTTON,
    content_action: TagEventsEnum.ACTION_CLICK,
    screen: 'Comprovante',
  })
}
