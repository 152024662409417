import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  height: calc(100vh - 54px);

  > :first-child {
    margin: 24px 0 16px;
  }
`

export const Footer = styled.div`
  margin: auto 0 24px;
`
