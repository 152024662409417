import BaseBridge from 'config/bridge/BaseBridge'
import { TagEventsEnum } from 'enums/TagEvents'

export const AnalyticsAfterSalesDetails = (name: string) => {
  BaseBridge.requestAnalytics(name, {
    ref_figma: '19',
    screen: 'Pós-venda',
    ref_type: TagEventsEnum.REF_BUTTON,
    content_action: TagEventsEnum.ACTION_CLICK,
    action_id: 'Chamar deeplink de cancelamento de assinatura',
  })
}
