import styled from 'styled-components'

export const Plans = styled.div`
  > :first-child {
    margin-bottom: 16px;
    padding: 0 24px;
    text-align: center;
  }
`

export const Slider = styled.div`
  .slider {
    .slick-slider {
      .slick-track {
        display: flex;
      }

      .slick-slide {
        justify-content: flex-start;

        > div {
          width: 85%;
        }
      }

      .slick-dots {
        bottom: -32px;

        li {
          opacity: 1;
          transition: all 0.3s linear;
          background: var(--primary200);

          &.slick-active {
            background: var(--primary500);
          }
        }
      }
    }
  }
`
export const SliderContent = styled.div`
  padding: 16px;
  border: 1px solid var(--gray200);
  border-radius: 8px;

  h2 {
    margin: 16px 0;

    strong {
      font-size: 16px;
    }
  }

  button {
    padding: 0;
    margin-top: 16px;
  }
`

export const Coverages = styled.ul`
  min-height: 168px;

  li {
    margin: 8px 0 0 16px;

    &:first-child {
      margin-top: 0;
    }
  }
`
