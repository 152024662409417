import ShimmerHero from 'assets/img/shimerHero.svg'

import { Flex } from '@interco/inter-ui/components/Flex'
import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import { ShimmerBody, ShimmerContainer, ShimmerHeader } from './ShimmerHome.styles'

export const ShimmerHome = () => (
  <ShimmerContainer>
    <ShimmerHeader>
      <img src={ShimmerHero} alt="Imagem mostra o shimmer do hero" />
    </ShimmerHeader>

    <ShimmerBody>
      <Skeleton width="100%" height="60px" style={{ marginBottom: '16px' }} />

      <Skeleton width="64%" height="16px" style={{ marginBottom: '4px' }} />
      <Skeleton width="80%" height="16px" style={{ marginBottom: '4px' }} />
      <Skeleton width="100%" height="16px" style={{ marginBottom: '24px' }} />

      <Skeleton width="100%" height="50px" style={{ marginBottom: '16px' }} />

      <Flex
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{
          margin: '16px 0',
          padding: '16px',
          border: '1px solid var(--gray100)',
          borderRadius: '8px',
        }}
      >
        <Skeleton width="24px" height="24px" />
        <Skeleton width="72%" height="16px" />
        <Skeleton width="24px" height="24px" />
      </Flex>

      <Skeleton width="100%" height="16px" style={{ marginBottom: '24px' }} />
      <Skeleton width="100%" height="50px" style={{ marginBottom: '16px' }} />

      <Skeleton width="80%" height="290px" style={{ marginBottom: '24px' }} />
    </ShimmerBody>
  </ShimmerContainer>
)
