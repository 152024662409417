import { ILocation } from 'store/coveredCities/types'
import { WithHistory } from 'store/utils/provider'
import { IPet } from 'types/IRegister'

interface ICoverage {
  title: string
}

export interface IPlans {
  id: string
  name: string
  price: number
  coverages: ICoverage[]
  more: IPLanDetails
}

export interface IPLanDetailsItens {
  title: string
  content: string[]
  urlLink?: string
}

export interface IPLanDetails {
  title: string
  subTitle: string
  items: IPLanDetailsItens[]
}

export interface IPetBreed {
  id: number
  name: string
}

export interface ISubscription {
  header: {
    urlImage: string
  }
  location: ILocation
  coparticipation: {
    urlPdfCoparticipation: string
    urlPdfGracePeriods: string
  }
  plans: IPlans[]
  footer: {
    urlImage: string
  }
}
export interface ISubscriptionRequest {
  ibgeCode?: string
}

export interface ICreateSubscriptionRequest extends WithHistory {
  pet: IPet
}

export interface ICreateSubscriptionResponse {
  bankAccount: string
  petName: string
  petType: string
  ibgeCode: string
}

export const INITIAL_STATE: ISubscription = {
  header: {
    urlImage: '',
  },
  location: {
    city: '',
    uf: '',
    ibgeCode: '',
  },
  coparticipation: {
    urlPdfCoparticipation: '',
    urlPdfGracePeriods: '',
  },
  plans: [
    {
      id: '1b436119-f9c5-46d5-a7a5-73b9d8ddd733',
      name: 'Inter Standard',
      price: 39.9,
      coverages: [
        {
          title: 'Consultas em horário normal',
        },
        {
          title: 'Consultas em horário de plantão',
        },
        {
          title: 'Vacinas obrigatórias',
        },
        {
          title: 'Exames gerais',
        },
        {
          title: 'Raio x',
        },
        {
          title: 'Procedimentos clínicos',
        },
        {
          title: 'Exames de imagem',
        },
      ],
      more: {
        title: 'Plano Standard',
        subTitle:
          'O plano perfeito para quem busca por atendimentos de prevenção à saúde para seus pets.',
        items: [
          {
            title: 'Consultas',
            content: ['Consultas: R$ 20,00', 'Exames: R$ 30,00', 'Procedimentos: R$ 50,00'],
          },
          {
            title: 'Exames de imagem',
            content: ['FIV/FELV Teste rápido'],
          },
          {
            title: 'Carência',
            content: [
              'As carências começam a contar ao final da contratação do plano. Você pode consultar todos os prazos para cada uma dos procedimentos e todos os valores de coparticipação na tabela de procedimentos abaixo.',
            ],
            urlLink: 'https://www.google.com',
          },
        ],
      },
    },
  ],
  footer: {
    urlImage: '',
  },
}

export interface SubscriptionState {
  subscription: ISubscription
  planDetails: IPLanDetails
  dogBreeds: IPetBreed[]
  catBreeds: IPetBreed[]
  getSubscription: (payload?: ISubscriptionRequest) => void
  postSubscription: (payload: ICreateSubscriptionRequest) => void
  setPlanDetails: (planDetails: IPLanDetails) => void
  getPetBreeds: (petGender: string) => void
}
