import NewRelicUtils from 'config/monitoring/NewRelicUtils'
import { IWbISubscription } from 'types/IStart'
import { PetTypeEnum } from 'enums/PetType'

import InterWebviewBridge, {
  interWbAuth,
  interWbNavigate,
  interWbSession,
  IWbAppInfo,
  IWbISafeResponse,
  IWbUserInfo,
  WbSessionActions,
} from '@interco/inter-webview-bridge'

import BridgeMock from './BridgeMock.json'

interface IRequestISafe {
  category: string
  checkType: string
  feature: string
  value: number
}

export interface IWbUserLocation {
  latitude: string
  longitude: string
  city: string
}
export class BaseBridge {
  private static backNavigationHandler: (() => void) | null = null

  private static addUrlProtocol(url: string) {
    const HTTPS_PROTOCOL = 'https://'
    const HTTP_PROTOCOL = 'http://'
    if (url.startsWith(HTTPS_PROTOCOL) || url.startsWith(HTTP_PROTOCOL)) {
      return url
    }
    return HTTPS_PROTOCOL + url
  }

  static isBrowser() {
    const iWb = InterWebviewBridge.getInstance()

    if (process.env.REACT_APP_BRIDGE_DEBUG === 'true') {
      iWb.setDebug(true)
    }

    return iWb.getEnvironment() === 'BROWSER'
  }

  static isIOS() {
    const iWb = InterWebviewBridge.getInstance()

    if (process.env.REACT_APP_BRIDGE_DEBUG === 'true') {
      iWb.setDebug(true)
    }

    return iWb.getEnvironment() === 'IOS'
  }

  static isAndroid() {
    const iWb = InterWebviewBridge.getInstance()

    if (process.env.REACT_APP_BRIDGE_DEBUG === 'true') {
      iWb.setDebug(true)
    }

    return iWb.getEnvironment() === 'ANDROID'
  }

  static async requestSubscriptionPages() {
    const iWb = InterWebviewBridge.getInstance()

    if (BaseBridge.isBrowser()) {
      return BridgeMock.getSubscription as IWbISubscription
    }

    try {
      return await iWb.execute({
        action: 'requestSubscriptionPages',
        metadata: { productId: 'SEGURO_PET_2' },
      })
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseBridge.requestSubscriptionPages',
      })

      return Promise.reject(error)
    }
  }

  static async sendToSubscription() {
    const iWb = InterWebviewBridge.getInstance()

    if (BaseBridge.isBrowser()) {
      return BridgeMock.getSubscription as IWbISubscription
    }

    try {
      const petData = {
        name: 'Fido',
        type: PetTypeEnum.DOG,
        ibgeCode: 13456,
        location: 'Recife/PE',
        birthday: '10/04/2023',
        breed: 'Lulu da Pomerânia',
        breedId: 120,
        gender: 'MALE',
      }

      return await iWb.execute({
        action: 'sendToSubscription',
        metadata: { productId: 'SEGURO_PET_2', ...petData },
      })
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseBridge.sendToSubscription',
      })

      return Promise.reject(error)
    }
  }

  static async sendToCancelSubscription(subscriptionId?: string): Promise<IWbISubscription> {
    const iWb = InterWebviewBridge.getInstance()

    if (BaseBridge.isBrowser()) {
      return BridgeMock.getSubscription as IWbISubscription
    }

    try {
      return await iWb.execute({
        action: 'sendToCancelSubscription',
        metadata: { productId: 'SEGURO_PET_2', subscriptionId },
      })
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseBridge.sendToCancelSubscription',
      })

      return Promise.reject(error)
    }
  }

  static openDeepLink(deepLink: string) {
    if (!BaseBridge.isBrowser()) {
      interWbNavigate.openDeepLink(deepLink)
    }
  }

  static openLinkAndroid(link: string) {
    try {
      return interWbNavigate.openNativeBrowser(link)
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseBridge.openLinkAndroid',
      })

      return Promise.reject(error)
    }
  }

  static async openLinkIOS(url: string): Promise<void> {
    if (this.isBrowser()) {
      window.open(url, '_blank', 'noopener,noreferrer')
      return
    }

    try {
      const iWb = InterWebviewBridge.getInstance()

      await iWb.execute({
        action: 'openBrowser',
        metadata: {
          url,
        },
      })
    } catch (e) {
      NewRelicUtils.noticeError(e as Error, {
        errorCodeRef: 'BaseBridge.openBrowser',
      })
    }
  }

  // TODO: Obsolete code, use OpenDeeplink instead
  static openLinkOnBrowser(link: string) {
    if (BaseBridge.isBrowser()) {
      window.open(link, '_blank', 'noopener,noreferrer')
      return
    }

    if (BaseBridge.isAndroid()) {
      BaseBridge.openLinkAndroid(link)
      return
    }

    if (BaseBridge.isIOS()) {
      BaseBridge.openLinkIOS(link)
    }
  }

  static callPhoneNumber(phoneNumber: string) {
    if (!BaseBridge.isBrowser()) {
      interWbNavigate.callPhoneNumber(phoneNumber)
    }
  }

  static async requestISafe(data: IRequestISafe) {
    if (BaseBridge.isBrowser()) {
      return BridgeMock.requestISafe as IWbISafeResponse
    }

    try {
      return await interWbAuth.requestISafe(data.category, data.checkType, data.feature, data.value)
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseBridge.requestISafe',
      })

      return Promise.reject(error)
    }
  }

  static addBackListener(event: () => void) {
    if (!BaseBridge.isBrowser()) {
      const iWb = InterWebviewBridge.getInstance()

      if (BaseBridge.backNavigationHandler) {
        iWb.removeWebviewEventListener(WbSessionActions.GO_BACK, BaseBridge.backNavigationHandler)
      }

      iWb.addWebviewEventListener(WbSessionActions.GO_BACK, event)

      BaseBridge.backNavigationHandler = event
    }
  }

  static requestGoBack() {
    if (!BaseBridge.isBrowser()) {
      interWbNavigate.requestGoBack()
    }
  }

  static async requestBabi() {
    if (!BaseBridge.isBrowser()) {
      try {
        interWbNavigate.requestBabi()
      } catch (error) {
        NewRelicUtils.noticeError(error as Error, {
          errorCodeRef: 'BaseBridge.requestBabi',
        })
      }
    }
  }

  static async getUserInfo() {
    if (BaseBridge.isBrowser()) {
      return BridgeMock.getUserInfo as IWbUserInfo
    }

    try {
      return await interWbSession.getUserInfo()
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseBridge.getUserInfo',
      })

      return Promise.reject(error)
    }
  }

  static async getAppInfo() {
    if (BaseBridge.isBrowser()) {
      return BridgeMock.getAppInfo as IWbAppInfo
    }

    try {
      return await interWbSession.getAppInfo()
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseBridge.getAppInfo',
      })

      return Promise.reject(error)
    }
  }

  static async getUserLocation() {
    if (BaseBridge.isBrowser()) {
      return BridgeMock.getUserLocation as IWbUserLocation
    }

    try {
      return await interWbSession.getUserLocation()
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseBridge.getAppInfo',
      })

      return Promise.reject(error)
    }
  }

  static async openPdf(url: string, title: string): Promise<void> {
    if (this.isBrowser()) {
      window.open(BaseBridge.addUrlProtocol(url), '_blank')?.focus()
      return
    }
    try {
      await interWbNavigate.openPdf(title, url)
    } catch (e) {
      NewRelicUtils.noticeError(e as Error, {
        errorCodeRef: 'BridgeService.openPdf.displayPdf',
      })
    }
  }

  static requestAnalytics(name: string, params?: Record<string, string>) {
    if (!BaseBridge.isBrowser()) {
      interWbSession.requestAnalytics(name, params)
    }
  }
}

export default BaseBridge
