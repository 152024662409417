import { useEffect, useState } from 'react'

import { ActionIconEnum } from 'enums/ActionIcon'
import { AfterSalesStatusEnum } from 'enums/AfterSalesStatus'
import { PageTitles } from 'enums/PageTitles'
import { PetTypeEnum } from 'enums/PetType'
import { TagEventsEnum } from 'enums/TagEvents'
import { useNavBar } from 'hooks/useNavBar'
import { useHistory } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { IPet, IPetType } from 'types/IRegister'
import { capitalizeFirstLetter, formatISODate } from 'utils/commons'

import {
  Option,
  SelectBottomSheet,
} from '@interco/inter-ui/components/BottomSheet/SelectBottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import { Input } from '@interco/inter-ui/components/Input'
import { Text } from '@interco/inter-ui/components/Text'

import { Calendar } from './Calendar/Calendar'
import { Pets } from './Pets/Pets'
import * as S from './Register.styles'
import { AnalyticsRegisterClick, AnalyticsRegisterOpen } from './Register.tags'

const genderOptions: Option[] = [
  { text: 'Macho', value: 'MALE' },
  { text: 'Fêmea', value: 'FEMALE' },
]

export const Register = () => {
  const history = useHistory()

  const isLoading = useBoundState((state) => state.isLoading)
  const { location } = useBoundState((state) => state.subscription)
  const afterSales = useBoundState((state) => state.afterSales)
  const dogBreeds = useBoundState((state) => state.dogBreeds)
  const catBreeds = useBoundState((state) => state.catBreeds)
  const getPetBreeds = useBoundState((state) => state.getPetBreeds)
  const postSubscription = useBoundState((state) => state.postSubscription)

  const [pet, setPet] = useState<IPet>({
    name: '',
    type: PetTypeEnum.NONE,
    ibgeCode: location.ibgeCode,
    location: location.city,
    birthday: '',
    breed: '',
    breedId: 0,
    gender: '',
  })

  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [selectGender, setSelectGender] = useState<Option | undefined>(undefined)
  const [selectBreed, setSelectBreed] = useState<Option | undefined>(undefined)
  const [breeds, setBreeds] = useState<Option[]>([])
  const [date, setDate] = useState<Date | undefined>(undefined)

  const petsList: IPetType[] = [PetTypeEnum.DOG, PetTypeEnum.CAT]

  useNavBar({
    navbarTitle: PageTitles.PET,
    actionIcons: {
      leftIcon: ActionIconEnum.BACK,
      rightIcon: ActionIconEnum.HOME,
    },
  })

  useEffect(() => {
    AnalyticsRegisterOpen(TagEventsEnum.PET_FLOW)
  }, [])

  useEffect(() => {
    if (dogBreeds.length === 0) getPetBreeds(PetTypeEnum.DOG)
  }, [dogBreeds, getPetBreeds])

  useEffect(() => {
    if (catBreeds.length === 0) getPetBreeds(PetTypeEnum.CAT)
  }, [catBreeds, getPetBreeds])

  const petBreedsAsSelectOptions = (petType: PetTypeEnum) => {
    const options: Option[] = []

    petType === PetTypeEnum.DOG
      ? dogBreeds.forEach((dogBreed) => {
          options.push({ text: dogBreed.name, value: dogBreed.id })
        })
      : catBreeds.forEach((catBreed) => {
          options.push({ text: catBreed.name, value: catBreed.id })
        })

    setBreeds(options)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowErrorMessage(false)
    const { name, value } = event.target
    setPet((prevPet) => ({ ...prevPet, [name]: value }))
  }

  const handleTypeChange = (type: PetTypeEnum) => {
    setShowErrorMessage(false)
    setPet((prevPet) => ({ ...prevPet, type, breed: '', breedId: 0 }))
    petBreedsAsSelectOptions(type)
  }

  const handleGenderChange = (choice: Option) => {
    const { value: gender } = choice as unknown as { value: string }

    setPet((prevPet) => ({ ...prevPet, gender }))
    setSelectGender(choice)
  }

  const handleBreedChange = (choice: Option) => {
    const { text, value } = choice as unknown as { text: string; value: number }

    setPet((prevPet) => ({ ...prevPet, breed: text, breedId: value }))
    setSelectBreed(choice)
  }

  const handleBirthdayChange = (newDate: Date) => {
    setDate(newDate)
    setPet((prevPet) => ({ ...prevPet, birthday: formatISODate(newDate) }))
  }

  const isPetAlreadySubscribed = () => {
    if (afterSales?.pets.length > 0) {
      const findPet = afterSales.pets.filter(
        (petFilter) =>
          petFilter.name.trim() === pet.name.trim() &&
          petFilter.type === pet.type &&
          petFilter.status === AfterSalesStatusEnum.ACTIVE,
      )

      return findPet.length > 0
    }

    return false
  }

  const handleSubscriptionViaDeeplink = async (e: { preventDefault: () => void }) => {
    e.preventDefault()

    if (isPetAlreadySubscribed()) {
      setShowErrorMessage(true)
    } else {
      AnalyticsRegisterClick(TagEventsEnum.PET_FLOW, {
        animal_type: pet.type,
        pet_name: pet.name,
        ibge_code: location.ibgeCode,
        location: location.city,
        birthday: pet.birthday,
        gender: pet.gender,
        breed: pet.breed,
        breedId: `${pet.breedId}`,
      })

      postSubscription({
        pet,
        history,
        pathname: TypesRoutes.START,
      })
    }
  }

  const isFormValid = () => {
    const valid =
      pet.name !== '' &&
      pet.type !== PetTypeEnum.NONE &&
      pet.birthday !== '' &&
      pet.gender !== '' &&
      pet.breedId !== 0

    return valid
  }

  return (
    <S.Container>
      <Text variant="headline-h2" as="h2" semiBold>
        Hora de conhecer o seu pet!
      </Text>

      <S.Form onSubmit={handleSubscriptionViaDeeplink}>
        <S.InputWrapper>
          <Text variant="body-3" bold colorWeight={500}>
            Nome
          </Text>

          <Input
            type="text"
            id="simple"
            name="name"
            color="var(--primary500)"
            placeholder="Digite o nome do seu pet"
            value={capitalizeFirstLetter(pet.name)}
            onChange={handleInputChange}
          />
          {showErrorMessage && (
            <Text variant="caption-1" as="span" className="errorMessage">
              Ops, no momento {pet.name} já tem um plano ativo.
            </Text>
          )}
        </S.InputWrapper>

        <S.InputWrapper>
          <Text variant="body-3" bold colorWeight={500}>
            Seu pet é um?
          </Text>

          <Pets pets={petsList} selectedItem={pet.type} onChange={handleTypeChange} />
        </S.InputWrapper>

        <S.InputWrapper>
          <Text variant="body-3" bold colorWeight={500}>
            Data de nascimento aproximada
          </Text>

          <Calendar date={date} onChange={handleBirthdayChange} />
        </S.InputWrapper>

        <S.InputWrapper>
          <Text variant="body-3" bold colorWeight={500}>
            Gênero
          </Text>

          <SelectBottomSheet
            id="selectGender"
            placeholder="Escolha uma opção..."
            className="selectClass"
            options={genderOptions}
            onChange={(value) => handleGenderChange(value)}
            value={selectGender}
          />
        </S.InputWrapper>

        <S.InputWrapper>
          <Text variant="body-3" bold colorWeight={500}>
            Raça
          </Text>

          <SelectBottomSheet
            disabled={breeds?.length === 0}
            id="selectGender"
            placeholder="Escolha uma opção..."
            isUsingFilter
            placeholderFilter="Busque por..."
            className="selectClass"
            options={breeds}
            onChange={(value) => handleBreedChange(value)}
            value={selectBreed}
          />
        </S.InputWrapper>

        <Button
          type="submit"
          variant="primary"
          disabled={!isFormValid() || isLoading}
          isLoading={isLoading}
        >
          Continuar
        </Button>
      </S.Form>
    </S.Container>
  )
}
