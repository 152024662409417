import styled from 'styled-components'

export const Container = styled.div`
  height: calc(100vh - 54px);

  div {
    height: 100%;
  }
`

export const Slider = styled.div`
  .slider {
    position: relative;

    .slick-slider {
      .slick-dots {
        width: auto;
        bottom: 32px;
        left: 24px;

        li {
          width: 24px;
          margin: 0 8px 0 0;
          opacity: 1;
          transition: all 0.3s linear;
          background: var(--gray200);

          &.slick-active {
            width: 24px;
            background: var(--primary500);
          }
        }
      }
    }
  }
`
export const SliderContent = styled.div`
  margin-top: 24px;

  img {
    max-width: 100%;
    height: auto;
  }
`

export const SliderBody = styled.div`
  padding: 24px;

  h1 {
    margin-bottom: 8px;
  }
`
