import { useState } from 'react'

import BaseBridge from 'config/bridge/BaseBridge'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

export const BridgeTest = () => {
  const [res, setRes] = useState('')

  const fetchRequestSubscriptionPages = () => {
    const response = BaseBridge.requestSubscriptionPages()

    setRes(JSON.stringify(response))
  }

  const fetchSendToSubscription = () => {
    const response = BaseBridge.sendToSubscription()

    setRes(JSON.stringify(response))
  }

  const fetchSendToCancelSubscription = () => {
    const response = BaseBridge.sendToCancelSubscription('628502e5-70ab-4f82-90af-9b2324984f34')

    setRes(JSON.stringify(response))
  }

  return (
    <>
      <Button onClick={fetchRequestSubscriptionPages}>
        <Text variant="caption-2" bold colorWeight={500}>
          requestSubscriptionPages
        </Text>
      </Button>
      <br />
      <br />

      <Button onClick={fetchSendToSubscription}>
        <Text variant="caption-2" bold colorWeight={500}>
          sendToSubscription
        </Text>
      </Button>
      <br />
      <br />

      <Button onClick={fetchSendToCancelSubscription}>
        <Text variant="caption-2" bold colorWeight={500}>
          sendToCancelSubscription
        </Text>
      </Button>
      <br />
      <br />

      <textarea rows={10} cols={40} value={`Resposta: ${res}`} />
      <br />
      <br />
      <br />
    </>
  )
}
