import BaseBridge from 'config/bridge/BaseBridge'
import BaseService from 'config/bridge/BaseService'
import { IReceipt } from 'store/receipt/types'

export default class ReceiptService {
  static async getReceipt(subscriptionId: string) {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        subscriptionId,
        'x-inter-user-bank-account': (await BaseBridge.getUserInfo()).account,
        'x-inter-user-name': (await BaseBridge.getUserInfo()).name,
        'x-inter-user-email': (await BaseBridge.getUserInfo()).email,
      }
    } else {
      headers = {
        subscriptionId,
      }
    }

    const response = await BaseService.doExecute<IReceipt>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_PET}/subscription/receipt`,
      headers,
    })

    return response.data
  }
}
