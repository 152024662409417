import { useState } from 'react'

import { AccordionCustom } from 'components'
import BaseBridge from 'config/bridge/BaseBridge'
import { useLocalErrorHandler } from 'hooks/useLocalErrorHandler'
import { IPLanDetailsItens } from 'store/subscription/types'

import { uuid } from '@interco/inter-ui'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './Accordions.styles'

interface IAccordionsProps {
  itens: IPLanDetailsItens[]
}

export const Accordions = ({ itens }: IAccordionsProps) => {
  const handlerError = useLocalErrorHandler()

  const [expandedAccordions, setExpandedAccordions] = useState<number[]>([])

  const isExpanded = (key: number) => expandedAccordions.includes(key)

  const handleAccordions = (key: number) => {
    isExpanded(key)
      ? setExpandedAccordions([...expandedAccordions.filter((x) => x !== key)])
      : setExpandedAccordions([...expandedAccordions, key])
  }

  const handleClickOpenPdf = async (pdfUrl: string | undefined, title: string) => {
    try {
      if (pdfUrl) {
        await BaseBridge.openPdf(pdfUrl, title)
      }
    } catch (error) {
      handlerError(error as Error, 'Home.openPdf')
    }
  }

  const renderAccordion = itens.map((detail, index) => (
    <AccordionCustom
      key={detail.title}
      title={detail.title}
      variantTitle="body-3"
      expanded={isExpanded(index)}
      onClick={() => handleAccordions(index)}
      data-testid={`accordion-${index}`}
    >
      <S.AccordionList>
        {detail.content.map((content) => (
          <Text key={uuid()} variant="body-3" as="li" colorWeight={500}>
            {content}
          </Text>
        ))}
      </S.AccordionList>

      {detail.urlLink && (
        <Button
          variant="link"
          onClick={() => handleClickOpenPdf(detail.urlLink, 'Coparticipação e Carência')}
        >
          Mostrar tabela de procedimentos
        </Button>
      )}
    </AccordionCustom>
  ))

  return <S.AccordionSection>{renderAccordion}</S.AccordionSection>
}
