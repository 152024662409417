import BaseBridge from 'config/bridge/BaseBridge'
import { TagEventsEnum } from 'enums/TagEvents'

export const AnalyticsAfterSales = (name: string) => {
  BaseBridge.requestAnalytics(name, {
    ref_figma: '18',
    screen: 'Pós-venda',
    ref_type: TagEventsEnum.REF_SCREEN,
    content_action: TagEventsEnum.ACTION_LOAD_DATA,
    action_id: 'Acessou a tela de pós venda do Seguro Pet v2.0',
  })
}
