import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 24px;

  > :first-child {
    padding: 24px 0 16px;
  }

  input {
    color: var(--typography500);
  }
`

export const CoveredCities = styled.div`
  height: calc(100vh - 202px);
  overflow: scroll;
`
