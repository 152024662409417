import styled from 'styled-components'

export const Choose = styled.label`
  padding: 4px;
  border: 1px solid var(--gray200);
  border-radius: 8px;
  margin-bottom: 16px;

  img {
    width: 100%;
    height: auto;
  }

  > div {
    span {
      margin-left: 4px;
    }
  }

  &.selected {
    border-color: var(--primary500);
  }
`
