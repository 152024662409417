import BaseBridge from 'config/bridge/BaseBridge'
import BaseService from 'config/bridge/BaseService'
import { ICoveredCitiesResponse } from 'store/coveredCities/types'
import { ICoveredClinicsResponse } from 'store/coveredClinics/types'

export default class AccreditedService {
  static async getCoveredCities() {
    const response = await BaseService.doExecute<ICoveredCitiesResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_PET}/coverage/cities`,
    })

    return response.data
  }

  static async getCoveredClinics(ibgeCode?: string) {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        ibgeCode,
        'x-inter-user-bank-account': (await BaseBridge.getUserInfo()).account,
      }
    } else {
      headers = {
        ibgeCode,
      }
    }

    const response = await BaseService.doExecute<ICoveredClinicsResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_PET}/coverage/clinics`,
      headers,
    })

    return response.data
  }
}
