import { useHistory, useLocation } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'

export function PathQueryParams() {
  const location = useLocation()
  const history = useHistory()
  const getCoveredClinicsAfterSales = useBoundState((state) => state.getCoveredClinicsAfterSales)
  const getLandingPage = useBoundState((state) => state.getLandingPage)
  const setCustomBackListener = useBoundState((state) => state.setCustomBackListener)

  enum DeepLinksEnum {
    PARTNER_CLINICS = 'clinics',
    POS_SALES = 'posSales',
    BRIDGE_TESTS = 'bridges',
    LANDING_PAGE = 'landingPage',
  }

  const getReceipt = useBoundState((state) => state.getReceipt)
  const getAfterSales = useBoundState((state) => state.getAfterSales)

  const whitelist = ['/', '/receipt']

  const handler = () => {
    if (whitelist.includes(location.pathname)) {
      const searchParams = new URLSearchParams(location.search)

      return setPathParams(location.pathname, searchParams)
    }

    return false
  }

  const setPathParams = (pathname: string, params: URLSearchParams) => {
    let hasParams = false
    if (params.getAll.length > 0 && pathname.includes('/')) {
      const subscriptionIdParams = {
        subscriptionId: params.get('subscriptionId') || undefined,
        url: params.get('url') || undefined,
      }

      if (subscriptionIdParams.subscriptionId) {
        getReceipt({ subscriptionId: subscriptionIdParams.subscriptionId })
      }

      if (subscriptionIdParams.url && subscriptionIdParams.url === DeepLinksEnum.PARTNER_CLINICS) {
        getCoveredClinicsAfterSales({ history, pathname: TypesRoutes.PARTNER_CLINICS })

        setCustomBackListener(() => history.push(TypesRoutes.START))
        hasParams = true
      }

      if (subscriptionIdParams.url && subscriptionIdParams.url === DeepLinksEnum.POS_SALES) {
        getAfterSales({
          onTransmission: () => {
            history.push(TypesRoutes.AFTER_SALES)
          },
        })

        setCustomBackListener(() => history.push(TypesRoutes.START))
        hasParams = true
      }

      if (subscriptionIdParams.url && subscriptionIdParams.url === DeepLinksEnum.LANDING_PAGE) {
        getLandingPage({
          history,
          pathname: TypesRoutes.HOME,
        })

        setCustomBackListener(() => history.push(TypesRoutes.START))
        hasParams = true
      }

      if (subscriptionIdParams.url && subscriptionIdParams.url === DeepLinksEnum.BRIDGE_TESTS) {
        history.push(TypesRoutes.BRIDGES)

        setCustomBackListener(() => history.push(TypesRoutes.START))
        hasParams = true
      }
    }

    return hasParams
  }

  return handler
}
