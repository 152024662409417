import BaseBridge from 'config/bridge/BaseBridge'
import { TagEventsEnum } from 'enums/TagEvents'

export const AnalyticsRegisterOpen = (name: string) => {
  BaseBridge.requestAnalytics(name, {
    screen: 'Adição de pets',
    ref_figma: '10',
    ref_type: TagEventsEnum.REF_SCREEN,
    content_action: TagEventsEnum.ACTION_LOAD_DATA,
    action_id: 'Acessou a tela de adição de pets',
  })
}

export const AnalyticsRegisterClick = (name: string, params: Record<string, string>) => {
  BaseBridge.requestAnalytics(name, {
    ...params,
    ref_figma: '11',
    screen: 'Adição de pets',
    action_id: 'Chamar central de assinaturas',
    ref_type: TagEventsEnum.REF_BUTTON,
    content_action: TagEventsEnum.ACTION_CLICK,
  })
}
