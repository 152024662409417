import { useEffect, useLayoutEffect } from 'react'

import { useLocalErrorHandler } from 'hooks/useLocalErrorHandler'
import { BrowserRouter } from 'react-router-dom'
import { ErrorDetails } from 'store/error/types'
import useBoundState from 'store'
import { ErrorIconEnum } from 'enums/ErrorIconEnum'

import { Theme, initTheme } from '@interco/inter-ui'

import { Navbar } from './components/Navbar/Navbar'
import BaseBridge from './config/bridge/BaseBridge'
import NewRelicUtils from './config/monitoring/NewRelicUtils'
import { ErrorView } from './pages/shared'
import Routes from './routes'

const App = () => {
  const handlerError = useLocalErrorHandler()
  const setForbiddenAccessError = useBoundState((state) => state.setHasError)

  const forbiddenAccessViaBrowserInPrd =
    process.env.NODE_ENV === 'production' && BaseBridge.isBrowser()

  const forbiddenAccessError: ErrorDetails = {
    title: 'Seguro PET',
    subTitle: 'Baixe o Super App do Inter e proteja o seu animalzinho.',
    disabledButton: true,
    disabledHome: true,
    icon: ErrorIconEnum.WARNING,
  }

  useLayoutEffect(() => {
    if (forbiddenAccessViaBrowserInPrd) {
      setForbiddenAccessError(forbiddenAccessError)
    }
    getChooseTheme()
  }, [])

  useEffect(() => {
    NewRelicUtils.setInitSettings()
  }, [])

  const getChooseTheme = () => {
    BaseBridge.getAppInfo()
      .then((response) => {
        const { isDarkMode } = response

        initTheme(Theme.PF, isDarkMode)
      })
      .catch((error) => handlerError(error as Error, 'BaseBridge.getAppInfo'))
  }

  return forbiddenAccessViaBrowserInPrd ? (
    <ErrorView />
  ) : (
    <BrowserRouter>
      <ErrorView />
      <Navbar />
      <Routes />
    </BrowserRouter>
  )
}

export default App
