import { useEffect, useState } from 'react'

import { ListDescription } from 'components'
import { ActionIconEnum } from 'enums/ActionIcon'
import { PageTitles } from 'enums/PageTitles'
import { TagEventsEnum } from 'enums/TagEvents'
import { useNavBar } from 'hooks/useNavBar'
import { useHistory } from 'react-router'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { ILocation } from 'store/coveredCities/types'
import { removeAccents } from 'utils/commons'
import { Icons } from 'utils/icons'

import { Alert } from '@interco/inter-ui/components/Alert'
import { SearchInput } from '@interco/inter-ui/components/SearchInput'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './Location.styles'
import { AnalyticsLocation } from './Location.tags'

export const Location = () => {
  const history = useHistory()

  const locations = useBoundState((state) => state.locations)
  const setCoveredClinics = useBoundState((state) => state.setCoveredClinics)
  const setCurrentLocation = useBoundState((state) => state.setCurrentLocation)
  const getSubscription = useBoundState((state) => state.getSubscription)

  const [locationsList, setLocationsList] = useState<ILocation[]>([])

  const icons = {
    right: <Icons.ChevronRight width={24} height={24} color="var(--primary500)" />,
  }

  let debounce: NodeJS.Timeout

  useNavBar({
    navbarTitle: PageTitles.PET,
    actionIcons: {
      leftIcon: ActionIconEnum.BACK,
    },
  })

  useEffect(() => {
    setLocationsList([...locations])
  }, [])

  const fetchSubscribeResponse = (location: ILocation) => {
    getSubscription({ ibgeCode: location.ibgeCode })

    setCoveredClinics([])

    setCurrentLocation({
      city: location.city,
      ibgeCode: location.ibgeCode,
    })

    AnalyticsLocation(TagEventsEnum.PET_FLOW, {
      city: location.city,
    })

    history.push(TypesRoutes.HOME)
  }

  const renderLocationList = locationsList.map((location) => (
    <ListDescription
      key={location.ibgeCode}
      id={location.ibgeCode}
      borderBottom
      iconRight={icons.right}
      margin="0 0 16px"
      onClick={() => fetchSubscribeResponse(location)}
    >
      <Text variant="caption-1" bold colorWeight={500}>
        {location.city}
      </Text>
    </ListDescription>
  ))

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement

    const searchValue = removeAccents(value, true)

    clearTimeout(debounce)

    debounce = setTimeout(() => {
      if (searchValue) {
        const updatedLocations = locations.filter((item) =>
          removeAccents(item.city, true).includes(searchValue),
        )

        setLocationsList(updatedLocations)
      } else {
        setLocationsList(locations)
      }
    }, 200)
  }

  return (
    <S.Container>
      <Text variant="headline-h3" as="h3" semiBold>
        Onde você deseja ter atendimento para seu pet?
      </Text>

      <SearchInput placeholder="Pesquisar cidade" isFullWidth onChange={handleSearch} />

      <S.CoveredCities>
        {locationsList.length === 0 && (
          <Alert title="Região não encontrada" type="grayscale">
            Estamos trabalhando junto com a Petlove para ampliar a nossa cobertura do Plano de Saúde
            Pet até a sua região.
          </Alert>
        )}

        {renderLocationList}
      </S.CoveredCities>
    </S.Container>
  )
}
