import useBoundState from 'store'

import { Text } from '@interco/inter-ui/components/Text'

import { Container, LogoImage } from './LogoPetlove.styles'

export const LogoPetlove = () => {
  const { footer } = useBoundState((state) => state.subscription)

  return (
    <Container>
      <Text variant="body-3">Em parceria com</Text>
      <LogoImage>
        <img src={footer.urlImage} alt="Logo Petlove" />
      </LogoImage>
    </Container>
  )
}
