import { AfterSalesStatusEnum } from 'enums/AfterSalesStatus'
import { getDayMonth } from 'utils/date'

import { Tag } from '@interco/inter-ui/components/Tag'

import * as S from './AfterSalesStatusTag.styles'

interface IStatusTag {
  status: string
  color: string
  colorText?: string
}

interface IProps {
  status: AfterSalesStatusEnum
  cancelDate?: string
}

export const AfterSalesStatusTag = ({ status, cancelDate }: IProps) => {
  const showCancelDate = () => {
    if (status === AfterSalesStatusEnum.ACTIVE && cancelDate) {
      return ` até ${getDayMonth(cancelDate)}`
    }

    return ''
  }

  type AfterSalesStatusEnumKeys = {
    [key in AfterSalesStatusEnum]: IStatusTag
  }

  const statusTags: AfterSalesStatusEnumKeys = {
    [AfterSalesStatusEnum.ACTIVE]: {
      status: 'Ativo',
      color: 'var(--success500)',
    },
    [AfterSalesStatusEnum.PARTNER_IN_PROCESS]: {
      status: 'Em análise',
      color: 'var(--alert100)',
      colorText: 'var(--gray500)',
    },
    [AfterSalesStatusEnum.SUSPENDED]: {
      status: 'Suspenso',
      color: 'var(--gray100)',
      colorText: 'var(--gray500)',
    },
    [AfterSalesStatusEnum.CANCELED]: {
      status: 'Cancelado',
      color: 'var(--error500)',
    },
  }

  return statusTags[status] ? (
    <S.StatusTag>
      <Tag colorText={statusTags[status].colorText} color={statusTags[status].color}>
        {statusTags[status].status}
        {showCancelDate()}
      </Tag>
    </S.StatusTag>
  ) : (
    <></>
  )
}
