import styled from 'styled-components'

export const Container = styled.div`
  height: calc(100vh - 56px);
  padding: 0 24px;

  > :first-child {
    padding: 24px 0 16px;
  }
`

export const Clinics = styled.div`
  span {
    display: block;
    margin-bottom: 8px;
  }
`

export const AttendanceTag = styled.div`
  margin-top: 8px;

  span {
    margin: 0;
    line-height: 120%;
  }
`

export const Alert = styled.div`
  margin-bottom: 24px;
`
export const CoveredCitiesBS = styled.div`
  > :first-child {
    > div {
      width: 100%;
    }
  }

  input {
    color: var(--typography500);
  }

  li span {
    color: var(--typography500);
  }
`
