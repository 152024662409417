import { useState } from 'react'

import { AccordionCustom } from 'components'
import { Icons } from 'utils/icons'
import BaseBridge from 'config/bridge/BaseBridge'

import { Button } from '@interco/inter-ui/components/Button'
import { Flex } from '@interco/inter-ui/components/Flex'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from '../../Common/Common.styles'

export const Accordions = () => {
  const PETLOVE_HELP_CENTER = 'https://atendimento.petlove.com.br/'
  const [expandedAccordions, setExpandedAccordions] = useState<number[]>([])

  const contactIcons = {
    desktop: <Icons.Desktop width={16} height={16} color="var(--gray500)" />,
    email: <Icons.Email width={16} height={16} color="var(--gray500)" />,
    phone: <Icons.Phone width={16} height={16} color="var(--gray500)" />,
  }

  const accordionIcons = {
    help: <Icons.Help width={24} height={24} color="var(--gray500)" />,
    apps: <Icons.Apps width={24} height={24} color="var(--gray500)" />,
    canceled: <Icons.Canceled width={24} height={24} color="var(--gray500)" />,
  }

  const isExpanded = (key: number) => expandedAccordions.includes(key)

  const handleAccordions = (key: number) => {
    isExpanded(key)
      ? setExpandedAccordions([...expandedAccordions.filter((x) => x !== key)])
      : setExpandedAccordions([...expandedAccordions, key])
  }

  return (
    <S.AccordionSection>
      <AccordionCustom
        key="1"
        icon={accordionIcons.help}
        title="Central de ajuda"
        expanded={isExpanded(1)}
        onClick={() => handleAccordions(1)}
      >
        <Text variant="caption-1">
          Acesse a central da nossa parceira Petlove e tire as principais dúvidas sobre a utilização
          do plano de saúde do seu pet.
        </Text>
        <Button
          variant="link"
          onClick={() => {
            BaseBridge.openDeepLink(PETLOVE_HELP_CENTER)
          }}
        >
          Acessar a central de ajuda Petlove
        </Button>
      </AccordionCustom>

      <AccordionCustom
        key="2"
        icon={accordionIcons.apps}
        title="Contatos"
        expanded={isExpanded(2)}
        onClick={() => handleAccordions(2)}
      >
        <Flex
          direction="row"
          justifyContent="flex-start"
          alignContent="flex-start"
          alignItems="center"
          gap="8px"
        >
          {contactIcons.desktop}
          <Text variant="caption-1" className="contactInfo" bold>
            www.saude.petlove.com.br
          </Text>
        </Flex>
        <Flex
          direction="row"
          justifyContent="flex-start"
          alignContent="flex-start"
          alignItems="center"
          gap="8px"
        >
          {contactIcons.email}
          <Text variant="caption-1" className="contactInfo" bold>
            contato@petlovesaude.com.br
          </Text>
        </Flex>
        <Flex
          direction="row"
          justifyContent="flex-start"
          alignContent="flex-start"
          alignItems="center"
          gap="8px"
        >
          {contactIcons.phone}
          <Text variant="caption-1" className="contactInfo" bold>
            3003-7190
          </Text>
        </Flex>

        <S.Weekdays>
          <Text variant="caption-1" as="p" bold>
            Atendimento:
          </Text>
          <Text variant="caption-1">
            De segunda a sexta, das 08h às 21h
            <br />
            Aos sábados, das 08h às 17h
          </Text>
        </S.Weekdays>
      </AccordionCustom>
    </S.AccordionSection>
  )
}
