import { StateCreator } from 'zustand'
import { TypesRoutes } from 'routes/mixedRoutes/types'

import { NavbarState } from './types'

export const createNavbarSlice: StateCreator<NavbarState, [], [], NavbarState> = (set) => ({
  title: '',
  actionIcons: { leftIcon: '', rightIcon: '' },
  hideNavbar: false,
  customBackListener: undefined,
  firstPage: TypesRoutes.START,

  setNavbarTitle: (title) => set(() => ({ title })),
  setActionIcons: (actionIcons) => set(() => ({ actionIcons })),
  setHideNavbar: (hideNavbar) => set(() => ({ hideNavbar })),
  setCustomBackListener: (listener) => set(() => ({ customBackListener: listener })),
  resetNavbar: () =>
    set(() => ({
      title: '',
      actionIcons: { leftIcon: '', rightIcon: '' },
      hideNavbar: false,
    })),
  setFirstPage: (firstPage) => set(() => ({ firstPage })),
})
