import { useCallback, useEffect, useState } from 'react'

import { AccordionCustom, ListDescription, Separator } from 'components'
import BaseBridge from 'config/bridge/BaseBridge'
import { ActionIconEnum } from 'enums/ActionIcon'
import { PageTitles } from 'enums/PageTitles'
import { TagEventsEnum } from 'enums/TagEvents'
import { useLocalErrorHandler } from 'hooks/useLocalErrorHandler'
import { useNavBar } from 'hooks/useNavBar'
import { useHistory } from 'react-router-dom'
import FaqService from 'services/FaqService'
import useBoundState from 'store'
import { IFaqResponse, IQuestions } from 'types/IFaq'
import { removeAccents } from 'utils/commons'
import { Icons } from 'utils/icons'

import { Babi } from '@interco/inter-ui/components/Babi'
import { Button } from '@interco/inter-ui/components/Button'
import { SearchInput } from '@interco/inter-ui/components/SearchInput'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './Faq.styles'
import { AnalyticsFaq } from './Faq.tags'
import { ShimmerFAQ } from './ShimmerFAQ/ShimmerFAQ'

export const Faq = () => {
  const history = useHistory()
  const handlerError = useLocalErrorHandler()

  const isLoading = useBoundState((state) => state.isLoading)
  const setIsLoading = useBoundState((state) => state.setIsLoading)

  const [faqResponse, setFaqResponse] = useState<IFaqResponse>({} as IFaqResponse)
  const [faq, setFaq] = useState<IQuestions[]>([])
  const [manualUrl, setManualUrl] = useState('')
  const [moreToShow, setMoreToShow] = useState(0)
  const [expandedAccordions, setExpandedAccordions] = useState<number[]>([])
  const [loadMore, setLoadMore] = useState(false)

  useNavBar({
    navbarTitle: PageTitles.PET,
    actionIcons: {
      leftIcon: ActionIconEnum.BACK,
    },
  })

  const icons = {
    manual: <Icons.Manual width={24} height={24} color="var(--gray500)" />,
    right: <Icons.ChevronRight width={24} height={24} color="var(--primary500)" />,
  }

  const SHOW_QUESTIONS = 7

  let debounce: NodeJS.Timeout

  const fetchFaqResponse = useCallback(async () => {
    setIsLoading(true)

    await FaqService.getFaq()
      .then((response) => {
        setFaqResponse(response)
      })
      .catch((error) => {
        handlerError(error as Error, 'FaqService.getFaq')
      })

    setIsLoading(false)
  }, [handlerError, setIsLoading])

  useEffect(() => {
    fetchFaqResponse()
  }, [fetchFaqResponse])

  useEffect(() => {
    AnalyticsFaq(TagEventsEnum.PET_FLOW)
  }, [])

  useEffect(() => {
    if (faqResponse.questionList && faqResponse.urlGuide) {
      setFaq(faqResponse.questionList.slice(0, SHOW_QUESTIONS))
      setManualUrl(faqResponse.urlGuide)
      setMoreToShow(SHOW_QUESTIONS)
      setLoadMore(true)
    }
  }, [faqResponse.questionList, faqResponse.urlGuide])

  const isExpanded = (key: number) => expandedAccordions.includes(key)

  const handleAccordions = (key: number) => {
    isExpanded(key)
      ? setExpandedAccordions([...expandedAccordions.filter((x) => x !== key)])
      : setExpandedAccordions([...expandedAccordions, key])
  }

  const renderFaqList = faq.map((item, index) => (
    <AccordionCustom
      key={item.question}
      title={item.question}
      variantTitle="body-3"
      expanded={isExpanded(index)}
      onClick={() => handleAccordions(index)}
    >
      <Text variant="caption-1">{item.answer}</Text>
    </AccordionCustom>
  ))

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement

    const search = removeAccents(value, true)
    setLoadMore(false)
    clearTimeout(debounce)

    debounce = setTimeout(() => {
      if (search.length === 0) {
        setFaq(faqResponse.questionList.slice(0, SHOW_QUESTIONS))
        setMoreToShow(SHOW_QUESTIONS)
        setLoadMore(true)

        return
      }

      const questionsFound = faqResponse.questionList.filter((question) =>
        removeAccents(question.question, true).includes(search),
      )
      setFaq(questionsFound)
    }, 200)
  }

  const handleLoadQuestions = (): void => {
    const newQuestions = moreToShow + SHOW_QUESTIONS
    const newFaq = faqResponse.questionList.slice(moreToShow, newQuestions)

    setMoreToShow((prev) => prev + SHOW_QUESTIONS)
    setFaq((prevList) => [...prevList, ...newFaq])
    setLoadMore(newQuestions <= faqResponse.questionList.length)
  }

  const renderFooter = (
    <S.Footer>
      <Button
        variant="secondary"
        onClick={() => {
          history.goBack()
        }}
      >
        Voltar
      </Button>
      <Babi origin={TagEventsEnum.PRODUCT} />
    </S.Footer>
  )

  if (isLoading) {
    return <ShimmerFAQ />
  }

  return (
    <S.Container>
      <S.Faq>
        <SearchInput placeholder="Pesquisar" isFullWidth onChange={handleSearch} />

        {renderFaqList}

        {faq.length === 0 && <Text variant="body-3">Nenhum resultado encontrado.</Text>}

        {loadMore && (
          <Button variant="link" onClick={handleLoadQuestions}>
            Carregar mais
          </Button>
        )}
      </S.Faq>

      <Separator margin="32px 0" />

      <S.Manual>
        <Text variant="headline-h3" as="h3" semiBold>
          Detalhes do plano
        </Text>

        <ListDescription
          iconLeft={icons.manual}
          iconRight={icons.right}
          onClick={() => BaseBridge.openPdf(manualUrl, 'Manual do produto')}
        >
          <Text variant="caption-1" bold colorWeight={500}>
            Manual do produto
          </Text>
        </ListDescription>
      </S.Manual>

      {renderFooter}
    </S.Container>
  )
}
