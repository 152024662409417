/**
 * Rotas disponíveis na aplicação.
 */
export enum TypesRoutes {
  // HOME
  START = '/',
  HOME = '/home',
  PLAN_DETAILS = '/plan-details',

  // BRIDGES TESTS
  BRIDGES = '/bridges',

  // HOME

  // ACCREDITED CHAIN
  LOCATION = '/location',
  PARTNER_CLINICS = '/partner-clinics',

  // HIRING
  REGISTER = '/register',
  RECEIPT = '/receipt',

  // SHARED
  FAQ = '/faq',

  // AFTER SALES
  AFTER_SALES = '/after-sales',
  AFTER_SALES_DETAILS = '/after-sales-details',
  HOW_USE = '/how-use',

  // REQUEST_GO_BACK
  REQUEST_GO_BACK = 'requestGoBack',
}
