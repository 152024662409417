import { Loading } from '@interco/inter-ui/components/Loading'

import { Container, Content, Icon } from './ListDescription.styles'
import { IListDescriptionProps } from './ListDescription.types'

export const ListDescription = ({
  margin,
  iconLeft,
  iconRight,
  borderBottom,
  children,
  withBorder,
  borderColor = 'var(--gray200)',
  isLoading,
  small,
  ...props
}: IListDescriptionProps) => (
  <Container
    $margin={margin}
    $borderBottom={borderBottom}
    $withBorder={withBorder}
    $borderColor={borderColor}
    $isLoading={isLoading}
    small={small}
    {...props}
  >
    {!isLoading ? (
      <>
        {iconLeft && (
          <Icon $left data-testid="list-desc-left">
            {iconLeft}
          </Icon>
        )}

        <Content>{children}</Content>

        {iconRight && (
          <Icon $right data-testid="list-desc-right">
            {iconRight}
          </Icon>
        )}
      </>
    ) : (
      <Loading width={32} height={32} />
    )}
  </Container>
)
