import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
  padding: 0 24px;

  > :first-child {
    margin: 24px 0;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;

  span {
    margin-bottom: 8px;
  }

  .errorMessage {
    color: var(--error500);
    margin: 0 0 8px 0;
  }

  .errorMessage {
    color: var(--error500);
    margin: 0 0 8px 0;
  }

  > button {
    margin: 24px 0;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .selectClass {
    width: 100%;
  }
`
