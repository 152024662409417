import { useEffect } from 'react'

import { PageTitles } from 'enums/PageTitles'
import useBoundState from 'store'
import { typeIcons } from 'store/navbar/types'
import BaseBridge from 'config/bridge/BaseBridge'
import { useHistory, useLocation } from 'react-router-dom'

interface NavBarProps {
  navbarTitle: PageTitles
  actionIcons: typeIcons
}

export const useNavBar = ({ navbarTitle, actionIcons }: NavBarProps) => {
  const setNavbarTitle = useBoundState((state) => state.setNavbarTitle)
  const setActionIcon = useBoundState((state) => state.setActionIcons)
  const customBackListener = useBoundState((state) => state.customBackListener)
  const setCustomBackListener = useBoundState((state) => state.setCustomBackListener)
  const firstPage = useBoundState((state) => state.firstPage)

  const history = useHistory()
  const location = useLocation()

  const isAtFirstPage = location.pathname === firstPage

  const callAndResetCustomBackListener = () => {
    if (customBackListener) {
      customBackListener()
      setCustomBackListener(undefined)
    }
  }

  useEffect(() => {
    setNavbarTitle(navbarTitle)
    BaseBridge.addBackListener(() => history.goBack())

    if (isAtFirstPage) {
      BaseBridge.addBackListener(BaseBridge.requestGoBack)
    }

    if (customBackListener) {
      BaseBridge.addBackListener(callAndResetCustomBackListener)
    }

    if (actionIcons) {
      setActionIcon(actionIcons)
    }
  }, [])
}
