import { create } from 'zustand'

import { createAfterSalesSlice } from './afterSales'
import { AfterSalesState } from './afterSales/types'
import { createCoveredCitiesSlice } from './coveredCities'
import { CoveredCitiesState } from './coveredCities/types'
import { createCoveredClinicsSlice } from './coveredClinics'
import { CoveredClinicsState } from './coveredClinics/types'
import { createErrorSlice } from './error'
import { ErrorState } from './error/types'
import { createLandingPageSlice } from './landingPage'
import { LandingPageState } from './landingPage/types'
import { createLoadingSlice } from './loading'
import { LoadingState } from './loading/types'
import { createNavbarSlice } from './navbar'
import { NavbarState } from './navbar/types'
import { createPageTransitionSlice } from './pageTransition'
import { PageTransitionState } from './pageTransition/types'
import { createReceiptSlice } from './receipt'
import { ReceiptState } from './receipt/types'
import { createSubscriptionSlice } from './subscription'
import { SubscriptionState } from './subscription/types'
import { createEligibilitySlice } from './validation'
import { EligibilityState } from './validation/types'

type RootState = LoadingState &
  ErrorState &
  NavbarState &
  PageTransitionState &
  CoveredCitiesState &
  AfterSalesState &
  SubscriptionState &
  CoveredClinicsState &
  ReceiptState &
  LandingPageState &
  EligibilityState

const useBoundState = create<RootState>()((...a) => ({
  ...createErrorSlice(...a),
  ...createLoadingSlice(...a),
  ...createCoveredCitiesSlice(...a),
  ...createAfterSalesSlice(...a),
  ...createNavbarSlice(...a),
  ...createPageTransitionSlice(...a),
  ...createSubscriptionSlice(...a),
  ...createCoveredClinicsSlice(...a),
  ...createReceiptSlice(...a),
  ...createLandingPageSlice(...a),
  ...createEligibilitySlice(...a),
}))

export default useBoundState
