import BaseBridge from 'config/bridge/BaseBridge'
import { PetTypeEnum } from 'enums/PetType'
import SubscriptionService from 'services/SubscriptionService'
import useBoundState from 'store'
import { ErrorDetails } from 'store/error/types'
import {
  ICreateSubscriptionRequest,
  IPetBreed,
  IPLanDetails,
  ISubscriptionRequest,
} from 'store/subscription/types'
import { errorHandling, redirectRoute } from 'store/utils/provider'
import { StateCreator } from 'zustand'

import { INITIAL_STATE, SubscriptionState } from './types'

export const createSubscriptionSlice: StateCreator<SubscriptionState, [], [], SubscriptionState> = (
  set,
) => ({
  subscription: INITIAL_STATE,
  planDetails: {} as IPLanDetails,
  dogBreeds: [] as IPetBreed[],
  catBreeds: [] as IPetBreed[],

  setPlanDetails: (planDetails) => set({ planDetails }),

  getPetBreeds: async (petType) => {
    useBoundState.getState().setIsLoading(true)
    await SubscriptionService.getPetBreeds(petType)
      .then((response) => {
        petType === PetTypeEnum.DOG ? set({ dogBreeds: response }) : set({ catBreeds: response })
      })

      .catch((error) => {
        errorHandling(error as Error, 'SubscriptionService.getPetBreeds')
      })

    useBoundState.getState().setIsLoading(false)
  },

  getSubscription: async (payload) => {
    useBoundState.getState().setIsLoading(true)

    const { ibgeCode } = payload as ISubscriptionRequest

    await SubscriptionService.getSubscription(ibgeCode)
      .then((response) => {
        set({ subscription: response })
      })

      .catch((error) => {
        errorHandling(error as Error, 'SubscriptionService.getSubscription')
      })

    useBoundState.getState().setIsLoading(false)
  },

  postSubscription: async (payload) => {
    useBoundState.getState().setIsLoading(true)

    const { pet } = payload as ICreateSubscriptionRequest

    BaseBridge.requestAnalytics('PET_FRONT_PAYLOAD', {
      pet: JSON.stringify(payload),
    })

    await SubscriptionService.postSubscription(pet)
      .then(() => {
        BaseBridge.openDeepLink(
          'bancointer://centralDeAssinaturas/planos?idProduto=SEGURO_PET_2&titulo=Planos',
        )

        redirectRoute(payload)
      })

      .catch((error) => {
        const errorDetails: ErrorDetails = {
          title: 'Houve um erro por aqui',
          subTitle:
            'No momento, não foi possível realizar a contratação. Por favor tente novamente mais tarde',
          disabledButton: true,
        }

        BaseBridge.requestAnalytics('PET_FRONT_ERROR', {
          error_pet: JSON.stringify(error),
          animal_type: pet.type,
          pet_name: pet.name,
          ibge_code: pet.ibgeCode,
          location: pet.location,
          birthday: pet.birthday,
          gender: pet.gender,
          breed: pet.breed,
          breedId: `${pet.breedId}`,
        })

        errorHandling(error as Error, 'SubscriptionService.postSubscription', errorDetails)
      })

    useBoundState.getState().setIsLoading(false)
  },
})
