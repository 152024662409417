import BaseBridge from 'config/bridge/BaseBridge'
import { TagEventsEnum } from 'enums/TagEvents'

export const AnalyticsPlanDetails = (name: string, params: Record<string, string>) => {
  BaseBridge.requestAnalytics(name, {
    ...params,
    ref_figma: '15',
    screen: 'Detalhes',
    content_name: 'Detalhes dos planos',
    content_action: TagEventsEnum.ACTION_LOAD_DATA,
  })
}
