/**
 * Define o scroll no topo da página.
 */
export const scrollToTop = () => {
  window.scrollTo(0, 0)
}

/**
 * Formata uma determinada string no padrão dd/mm/yyyy para o padrão ISO 8601 yyyy-mm-dd
 * @param str data no formato dd/mm/yyyy
 * @returns data no formato yyyy-mm-dd
 */
export const formatISODate = (newDate: Date) => {
  const formattedISODate = newDate.toISOString().split('T')[0]

  return formattedISODate
}

/**
 * Converte a primeira letra da string em maiúsculo.
 *
 * @param str String a ser convertida em Maiúsculo.
 * @returns String com primeira letra em Maiúsculo.
 */
export const capitalizeFirstLetter = (str: string) => {
  try {
    const words = str.toLowerCase().split(' ')

    for (let i = 0; i < words.length; i++) {
      if (words[i].length > 2) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
      }
    }

    return words.join(' ')
  } catch {
    return str
  }
}

/**
 * Remove as acentuações da string.
 *
 * @param str String a ser removida a acentuação.
 * @param lowercase Indicador para informar se deseja transformar em lowercase.
 * @returns String sem acentuação.
 */
export const removeAccents = (str: string, lowercase = true) => {
  try {
    if (lowercase) {
      str = str.toLowerCase()
    }

    return str
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  } catch {
    return str
  }
}
