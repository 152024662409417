import { useEffect } from 'react'

import { Separator } from 'components'
import BaseBridge from 'config/bridge/BaseBridge'
import { TagEventsEnum } from 'enums/TagEvents'
import useBoundState from 'store'
import { PathQueryParams } from 'utils/pathQueryParams'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

import InsuranceImg from '../../../assets/img/insurance.png'
import * as S from './Receipt.styles'
import { AnalyticsReceiptClick } from './Receipt.tags'
import { ReceiptData } from './ReceiptData/ReceiptData'
import { ReceiptStatus } from './ReceiptStatus/ReceiptStatus'
import { ShimmerReceipt } from './ShimmerReceipt/ShimmerReceipt'

export const Receipt = () => {
  const handler = PathQueryParams()

  const isLoading = useBoundState((state) => state.isLoading)

  useEffect(() => {
    handler()
  }, [])

  const handleClickBanner = () => {
    AnalyticsReceiptClick(TagEventsEnum.BANNER, {
      ref_figma: '13',
      action_id: 'Chamar deeplink do seguro de vida',
    })

    BaseBridge.openDeepLink('bancointer://seguroVidaV2')
  }

  const handleClickHomeInsurance = () => {
    AnalyticsReceiptClick(TagEventsEnum.PET_RECEIPT, {
      ref_figma: '14',
      action_id: TagEventsEnum.ACTION_HOME_INSURANCE,
    })

    BaseBridge.openDeepLink('bancointer://seguros')
  }

  const renderInsurance = (
    <S.Insurance>
      <Separator borderWidth="1px" margin="24px 0" type="dashed" color="var(--gray200)" />

      <Text variant="headline-h3" as="h3" semiBold>
        Tem mais produtos para você se proteger com a Inter Seguros
      </Text>

      <S.InsuranceImage onClick={handleClickBanner}>
        <img src={InsuranceImg} alt="Imagem contem um gato de óculos escuros" />
      </S.InsuranceImage>
    </S.Insurance>
  )

  const renderFooter = (
    <S.Footer>
      <Button variant="secondary" onClick={handleClickHomeInsurance} disabled={isLoading}>
        Voltar para a home de seguros
      </Button>
    </S.Footer>
  )

  if (isLoading) {
    return <ShimmerReceipt />
  }

  return (
    <>
      <ReceiptStatus />
      <Separator margin="32px 0" />
      <ReceiptData />
      {renderInsurance}
      {renderFooter}
    </>
  )
}
