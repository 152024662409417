export enum TagEventsEnum {
  // default events
  BANNER = 'BANNER',

  // Pet
  PRODUCT = 'Seguro Pet 2.0',
  PET_FLOW = 'PLANO_PET_V2_FLUXO',
  PET_RECEIPT = 'PLANO_PET_V2_SUCESSO',

  // Actions
  ACTION_LOAD_DATA = 'Dado carregado',
  ACTION_CLICK = 'Clique',
  ACTION_HOME_INSURANCE = 'Voltar para a home de seguros',

  // REF TYPE
  REF_SCREEN = 'Tela',
  REF_BUTTON = 'Botão',
}
