import BaseBridge from 'config/bridge/BaseBridge'
import { TagEventsEnum } from 'enums/TagEvents'

export const AnalyticsHomeOpen = (name: string) => {
  BaseBridge.requestAnalytics(name, {
    ref_figma: '1',
    screen: 'Home do produto',
    ref_type: TagEventsEnum.REF_SCREEN,
    content_action: TagEventsEnum.ACTION_LOAD_DATA,
    action_id: 'Acessou o plano de saúde pet',
  })
}

export const AnalyticsHomeClick = (name: string, params: Record<string, string>) => {
  BaseBridge.requestAnalytics(name, {
    ...params,
    screen: 'Home do produto',
    ref_type: TagEventsEnum.REF_BUTTON,
    content_action: TagEventsEnum.ACTION_CLICK,
  })
}
