import { useEffect } from 'react'

import slider01 from 'assets/img/onboarding01.png'
import slider02 from 'assets/img/onboarding02.png'
import slider03 from 'assets/img/onboarding03.png'
import { ActionIconEnum } from 'enums/ActionIcon'
import { PageTitles } from 'enums/PageTitles'
import { TagEventsEnum } from 'enums/TagEvents'
import { useNavBar } from 'hooks/useNavBar'

import { Carousel } from '@interco/inter-ui/components/Carousel'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './HowUse.styles'
import { AnalyticsHowUse } from './HowUse.tags'

export const HowUse = () => {
  useNavBar({
    navbarTitle: PageTitles.EMPTY,
    actionIcons: {
      leftIcon: ActionIconEnum.BACK,
    },
  })

  useEffect(() => {
    AnalyticsHowUse(TagEventsEnum.PET_FLOW)
  }, [])

  const onBoarding = [
    {
      id: 1,
      image: slider01,
      title: 'Conheça a tabela de procedimentos',
      description:
        'Nela você encontra todos os procedimentos cobertos pelo plano, os valores de coparticipação e os prazos de carência.',
    },
    {
      id: 2,
      image: slider02,
      title: 'Seu pet atendido na clínica mais próxima',
      description:
        'Acesse a nossa Rede Credenciada e encontre as unidades prontas para cuidar da saúde do seu bichinho.',
    },
    {
      id: 3,
      image: slider03,
      title: 'Facilidade  no primeiro atendimento',
      description:
        'Ao usar o plano pela primeira vez, você deve verificar se é necessário agendamento prévio e levar apenas um documento com o seu CPF.',
    },
  ]

  return (
    <S.Container>
      <S.Slider>
        <Carousel
          dots
          arrows={false}
          infinite={false}
          speed={800}
          slidesToShow={1}
          dotsType="medium"
          verticalMode={false}
          className="slider"
        >
          {onBoarding.map((item) => (
            <S.SliderContent key={item.id}>
              <figure>
                <img src={item.image} alt="Imagem mostra cães e gatos sendo cuidados" />
              </figure>

              <S.SliderBody>
                <Text variant="headline-h1" as="h1" semiBold>
                  {item.title}
                </Text>
                <Text variant="body-3">{item.description}</Text>
              </S.SliderBody>
            </S.SliderContent>
          ))}
        </Carousel>
      </S.Slider>
    </S.Container>
  )
}
