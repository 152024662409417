import BaseBridge from 'config/bridge/BaseBridge'
import { TagEventsEnum } from 'enums/TagEvents'

export const AnalyticsFaq = (name: string) => {
  BaseBridge.requestAnalytics(name, {
    ref_figma: '16',
    screen: 'FAQ',
    content_name: 'FAQ',
    content_action: TagEventsEnum.ACTION_LOAD_DATA,
  })
}
