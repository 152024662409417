import { useEffect } from 'react'

import { Separator } from 'components'
import { TagEventsEnum } from 'enums/TagEvents'
import useBoundState from 'store'

import { Flex } from '@interco/inter-ui/components/Flex'
import { Text } from '@interco/inter-ui/components/Text'
import { formatToBRL } from '@interco/lib-util'

import { AnalyticsReceiptOpen } from '../Receipt.tags'
import * as S from './ReceiptData.style'

export const ReceiptData = () => {
  const { email, petGuardian, petName, location, billingDate, planName, price } = useBoundState(
    (state) => state.receipt,
  )

  useEffect(() => {
    AnalyticsReceiptOpen(TagEventsEnum.PET_RECEIPT, {
      plans: planName,
      value: formatToBRL(price),
      city: location,
    })
  }, [])

  const receiptDataView = [
    {
      label: 'Email',
      value: email,
    },
    {
      label: 'Tutor',
      value: petGuardian,
    },
    {
      label: 'Tipo do Plano',
      value: planName,
    },
    {
      label: 'Nome do Pet',
      value: petName,
    },
    {
      label: 'Cidade',
      value: location,
    },

    {
      label: 'Data da primeira cobrança',
      value: billingDate,
    },
  ]

  return (
    <S.Container>
      <S.Price>
        <Flex direction="column" justifyContent="center" alignItems="center">
          <Text variant="body-3" bold>
            Valor total
          </Text>
          <Text variant="headline-h1" as="h1" semiBold>
            {formatToBRL(price)}
            <strong>/mês</strong>
          </Text>
        </Flex>
      </S.Price>

      <Separator borderWidth="1px" type="dashed" color="var(--gray200)" margin="24px 0" />

      <S.Plan>
        <Text variant="headline-h3" as="h3" semiBold>
          Detalhes do plano
        </Text>
        <Text variant="body-3">Todos os detalhes foram enviados para o seu e-mail cadastrado.</Text>

        {receiptDataView.map((item) => (
          <Flex key={item.label} direction="row" gap="16px">
            <Text variant="caption-1">{item.label}</Text>
            <Text variant="caption-1" bold colorWeight={500}>
              {item.value}
            </Text>
          </Flex>
        ))}
      </S.Plan>
    </S.Container>
  )
}
