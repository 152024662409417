import { useEffect, useState } from 'react'

import { Separator } from 'components'
import { ActionIconEnum } from 'enums/ActionIcon'
import { PageTitles } from 'enums/PageTitles'
import { useNavBar } from 'hooks/useNavBar'
import useBoundState from 'store'

import { uuid } from '@interco/inter-ui'
import { SelectBottomSheet } from '@interco/inter-ui/components/BottomSheet/SelectBottomSheet'
import { Option } from '@interco/inter-ui/components/BottomSheet/SelectBottomSheet/types'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './PartnerClinics.styles'
import { ShimmerClinics } from './ShimmerClinics/ShimmerClinics'

export const PartnerClinics = () => {
  const isLoading = useBoundState((state) => state.isLoading)
  const partnerClinics = useBoundState((state) => state.clinics)
  const locationsList = useBoundState((state) => state.locations)
  const currentLocation = useBoundState((state) => state.currentLocation)

  const setCurrentLocation = useBoundState((state) => state.setCurrentLocation)
  const getCoveredClinics = useBoundState((state) => state.getCoveredClinics)

  const [currentCity, setCurrentCity] = useState<Option | undefined>(undefined)
  const [locationOptions, setLocationOptions] = useState<Option[]>([])

  useNavBar({
    navbarTitle: PageTitles.CLINICS,
    actionIcons: {
      leftIcon: ActionIconEnum.BACK,
    },
  })

  useEffect(() => {
    setCurrentCity({
      text: currentLocation.city,
      value: currentLocation.ibgeCode,
    })

    if (locationsList) {
      const options = locationsList.map((location) => ({
        text: location.city,
        value: location.ibgeCode,
      }))

      setLocationOptions(options)
    }
  }, [])

  const handleSelectCity = (value: Option) => {
    const ibgeCode = `${value.value}`

    getCoveredClinics({
      ibgeCode,
    })

    setCurrentCity(value)

    setCurrentLocation({
      city: value.text,
      ibgeCode,
    })
  }

  const partnerClinicsList = partnerClinics.map((clinic, index) => (
    <S.Clinics key={uuid()}>
      <Text variant="body-3" bold colorWeight={500}>
        {clinic.name}
      </Text>
      <Text variant="caption-1" bold color="primary" colorWeight={500}>
        {clinic.phone}
      </Text>
      <Text variant="caption-1" as="p" bold>
        {`${clinic.address.street}, ${clinic.address.number} ${
          clinic.address.neighborhood ? `- ${clinic.address.neighborhood}` : ''
        }`}
      </Text>

      {index !== partnerClinics.length - 1 && (
        <Separator borderWidth="1px" type="dashed" color="var(--gray200)" margin="16px 0" />
      )}
    </S.Clinics>
  ))

  const renderPartnerList =
    partnerClinics.length > 0 ? (
      partnerClinicsList
    ) : (
      <Text variant="body-3">
        No momento não há clínicas credenciadas nessa cidade, tente outra cidade
      </Text>
    )

  return (
    <S.Container>
      <Text variant="headline-h3" as="h3" semiBold>
        Encontre as clínicas parceiras mais perto de você
      </Text>

      <S.CoveredCitiesBS>
        <SelectBottomSheet
          id="select"
          placeholder="Selecione a cidade"
          isUsingFilter
          fixedHeight="60vh"
          placeholderFilter="Busque por..."
          options={locationOptions}
          onChange={handleSelectCity}
          value={currentCity}
          isLoading={isLoading}
        />
      </S.CoveredCitiesBS>

      {isLoading ? <ShimmerClinics /> : renderPartnerList}
    </S.Container>
  )
}
