import { paymentMethodLabelDictionary } from 'enums/PaymentMethodType'
import { convertStrToDate } from 'utils/date'
import { IPetAfterSales } from 'store/afterSales/types'

import { Flex } from '@interco/inter-ui/components/Flex'
import { Text } from '@interco/inter-ui/components/Text'
import { formatToBRL } from '@interco/lib-util/'

import { AfterSalesStatusTag } from '../Common/AfterSalesStatusTag/AfterSalesStatusTag'

interface IPetDetailsProps {
  afterSalesPet: IPetAfterSales
  hideCancelation: boolean
}

export const PetDetails = ({ afterSalesPet, hideCancelation }: IPetDetailsProps) => (
  <Flex direction="column" gap="16px">
    <Flex direction="row">
      <Text variant="body-3">Status</Text>
      <Text variant="body-3" bold colorWeight={500}>
        <AfterSalesStatusTag status={afterSalesPet.status} cancelDate={afterSalesPet.cancelDate} />
      </Text>
    </Flex>
    <Flex direction="row">
      <Text variant="body-3">Pet</Text>
      <Text variant="body-3" bold colorWeight={500}>
        {afterSalesPet?.name}
      </Text>
    </Flex>
    <Flex direction="row">
      <Text variant="body-3">Plano</Text>
      <Text variant="body-3" bold colorWeight={500}>
        {afterSalesPet?.planName}
      </Text>
    </Flex>
    <Flex direction="row">
      <Text variant="body-3">Localidade</Text>
      <Text variant="body-3" bold colorWeight={500}>
        {afterSalesPet?.location}
      </Text>
    </Flex>
    <Flex direction="row">
      <Text variant="body-3">Início de contratação</Text>
      <Text variant="body-3" bold colorWeight={500}>
        {convertStrToDate(afterSalesPet?.contractDate)}
      </Text>
    </Flex>
    <Flex direction="row">
      <Text variant="body-3">Valor mensalidade</Text>
      <Text variant="body-3" bold colorWeight={500}>
        {formatToBRL(Number(afterSalesPet?.price))}
      </Text>
    </Flex>
    {!hideCancelation && (
      <Flex direction="row">
        <Text variant="body-3">Data da próxima cobrança</Text>
        <Text variant="body-3" bold colorWeight={500}>
          {convertStrToDate(afterSalesPet?.paymentDate)}
        </Text>
      </Flex>
    )}
    {afterSalesPet?.cancelDate && (
      <Flex direction="row">
        <Text variant="body-3">Data de cancelamento</Text>
        <Text variant="body-3" bold colorWeight={500}>
          {convertStrToDate(afterSalesPet?.cancelDate)}
        </Text>
      </Flex>
    )}
    <Flex direction="row">
      <Text variant="body-3">Forma de pagamento</Text>
      <Text variant="body-3" bold colorWeight={500}>
        {paymentMethodLabelDictionary[afterSalesPet?.paymentMethod]}
      </Text>
    </Flex>
  </Flex>
)
