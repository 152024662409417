import { StateCreator } from 'zustand'

import { CoveredCitiesState, ILocation } from './types'

export const createCoveredCitiesSlice: StateCreator<
  CoveredCitiesState,
  [],
  [],
  CoveredCitiesState
> = (set) => ({
  locations: [],
  currentLocation: {} as ILocation,

  setCurrentLocation: (currentLocation) => set(() => ({ currentLocation })),
})
