import BaseBridge from 'config/bridge/BaseBridge'
import BaseService from 'config/bridge/BaseService'
import { IEligibilityResponse } from 'store/validation/types'

export default class ValidationService {
  static async getEligibility() {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        'x-inter-user-bank-account': (await BaseBridge.getUserInfo()).account,
      }
    }

    const response = await BaseService.doExecute<IEligibilityResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_PET}/subscription/validation`,
      headers,
    })

    return response.data
  }
}
