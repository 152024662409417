import { useEffect } from 'react'

import { ActionIconEnum } from 'enums/ActionIcon'
import { PageTitles } from 'enums/PageTitles'
import { TagEventsEnum } from 'enums/TagEvents'
import { useNavBar } from 'hooks/useNavBar'
import { useHistory } from 'react-router-dom'
import useBoundState from 'store'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

import { Accordions } from './Accordions/Accordions'
import * as S from './PlanDetails.styles'
import { AnalyticsPlanDetails } from './PlanDetails.tags'

export const PlanDetails = () => {
  const history = useHistory()

  const planDetails = useBoundState((state) => state.planDetails)

  useNavBar({
    navbarTitle: PageTitles.DETAILS,
    actionIcons: {
      leftIcon: ActionIconEnum.BACK,
      rightIcon: ActionIconEnum.FAQ,
    },
  })

  useEffect(() => {
    AnalyticsPlanDetails(TagEventsEnum.PET_FLOW, {
      plans: planDetails.title,
    })
  }, [])

  const renderStickFooter = (
    <S.Footer>
      <Button
        data-testid="btn-plan-back"
        variant="primary"
        fullWidth
        onClick={() => {
          history.goBack()
        }}
      >
        Voltar
      </Button>
    </S.Footer>
  )

  return (
    <S.Container>
      <Text variant="headline-h2" as="h2" semiBold>
        {planDetails.title}
      </Text>
      <Text variant="body-3">{planDetails.subTitle}</Text>

      <Accordions itens={planDetails.items} />

      {renderStickFooter}
    </S.Container>
  )
}
