import styled from 'styled-components'

const defaultDiv = styled.div`
  padding: 0 24px;
  button {
    padding-left: 0px;
  }
`

export const Sign = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
`

export const Container = styled.div`
  height: calc(100vh - 54px);
  overflow: scroll;
`

export const SubscriptionDetails = styled.div`
  margin: 24px 24px 0 24px;
  padding: 16px;
  border: 1px solid var(--gray200);
  border-radius: 8px;

  .statusTag {
    span {
      margin-left: 0px;
      margin-top: 4px;
    }
  }
`

export const CancelationAlert = styled.div`
  margin: 24px;
`

export const Footer = styled(defaultDiv)`
  margin-bottom: 24px;

  button {
    width: 100%;
    margin-bottom: 16px;
  }
`
