import { useCallback, useEffect } from 'react'

import { Separator } from 'components'
import { ActionIconEnum } from 'enums/ActionIcon'
import { PageTitles } from 'enums/PageTitles'
import { TagEventsEnum } from 'enums/TagEvents'
import { useNavBar } from 'hooks/useNavBar'
import { useHistory } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { convertStrToDate } from 'utils/date'
import { AfterSalesStatusEnum } from 'enums/AfterSalesStatus'

import { Babi } from '@interco/inter-ui/components/Babi'
import { Alert } from '@interco/inter-ui/components/Alert'

import { AccordionsPetDetails } from './AccordionsPetDetails/AccordionsPetDetails'
import * as S from './AfterSalesDetails.styles'
import { PetDetails } from './PetDetails'

export const AfterSalesDetails = () => {
  const afterSalesPet = useBoundState((state) => state.afterSalesPet)
  const afterSales = useBoundState((state) => state.afterSales)
  const history = useHistory()

  const hideCancelation =
    (afterSalesPet.status === AfterSalesStatusEnum.ACTIVE && !!afterSalesPet.cancelDate) ||
    afterSalesPet.status === AfterSalesStatusEnum.CANCELED

  const showCancelationAlert =
    afterSalesPet.status === AfterSalesStatusEnum.ACTIVE && !!afterSalesPet.cancelDate

  useNavBar({
    navbarTitle: PageTitles.PET,
    actionIcons: {
      leftIcon: ActionIconEnum.BACK,
    },
  })

  const redirectAfterCancelation = useCallback(() => {
    // Atualmente o cancelamento está sendo feito via url da Central de
    // assinaturas no Marketplace, porém ao retornar os dados se perdem.
    // A verificação abaixo leva o usuário para o início da aplicação caso isso aconteça.
    if (Object.keys(afterSalesPet).length === 0) {
      history.push(TypesRoutes.START)
    }
  }, [afterSalesPet, history])

  useEffect(() => {
    redirectAfterCancelation()
  }, [redirectAfterCancelation])

  return (
    <S.Container>
      <S.SubscriptionDetails>
        <PetDetails hideCancelation={hideCancelation} afterSalesPet={afterSalesPet} />
      </S.SubscriptionDetails>

      {showCancelationAlert && afterSalesPet.cancelDate && (
        <S.CancelationAlert>
          <Alert title="Solicitação de Cancelamento recebida" type="grayscale">
            Seu plano será encerrado em {convertStrToDate(afterSalesPet.cancelDate)}. Mas não se
            preocupe, seu Pet continuará protegido até lá.
          </Alert>
        </S.CancelationAlert>
      )}

      <Separator margin="32px 0" />
      <AccordionsPetDetails
        urlPdfGracePeriods={afterSales.urlPdfGracePeriods}
        afterSalesPet={afterSalesPet}
        hideCancelationAccordion={hideCancelation}
      />
      <Separator margin="32px 0" />
      <S.Footer>
        <Babi origin={TagEventsEnum.PRODUCT} />
      </S.Footer>
    </S.Container>
  )
}
