import { defaultError } from 'mocks/ResponseError'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import AccreditedService from 'services/AccreditedService'
import SubscriptionService from 'services/SubscriptionService'
import ValidationService from 'services/ValidationService'
import useBoundState from 'store'
import { errorHandling, redirectRoute } from 'store/utils/provider'
import { StateCreator } from 'zustand'

import { ILandingPageRequest, LandingPageState } from './types'

export const createLandingPageSlice: StateCreator<
  LandingPageState,
  [],
  [],
  LandingPageState
> = () => ({
  getLandingPage: async (payload) => {
    useBoundState.getState().setIsLoading(true)

    const { ibgeCode } = payload as ILandingPageRequest

    const eligibilityPromise = ValidationService.getEligibility()
    const subscriptionPromise = SubscriptionService.getSubscription(ibgeCode)
    const coveredCitiesPromise = AccreditedService.getCoveredCities()

    try {
      const [eligibilityResponse, subscriptionResponse, coveredCitiesResponse] = await Promise.all([
        eligibilityPromise,
        subscriptionPromise,
        coveredCitiesPromise,
      ])

      useBoundState.setState({
        eligibility: eligibilityResponse.eligibility,
        subscription: subscriptionResponse,
        locations: [...coveredCitiesResponse.locations],
      })

      redirectRoute(payload)
    } catch (error) {
      errorHandling(error as Error, 'SubscriptionService.getLandingPage', {
        ...defaultError,
        route: TypesRoutes.START,
      })
    } finally {
      useBoundState.getState().setIsLoading(false)
    }
  },
})
