import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 24px;
`

export const Price = styled.div`
  h1 {
    margin-top: 4px;

    strong {
      font-size: 16px;
    }
  }
`

export const Plan = styled.div`
  h3 {
    margin-bottom: 16px;
  }

  span {
    margin-top: 16px;
  }
`
