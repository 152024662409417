import styled from 'styled-components'

export const AccordionContent = styled.div`
  > div {
    padding-right: 0;
    padding-left: 0;
    -webkit-tap-highlight-color: transparent;
  }
`

export const Header = styled.div<{ expanded: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    flex: 1;
    margin-right: 16px;
  }

  svg {
    &:first-child {
      margin-right: 16px;
    }
    &:last-child {
      transition: all 0.35s;
      ${(props) => props.expanded && `transform: rotate(180deg);`}
    }
  }
`
