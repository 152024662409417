export enum PaymentMethodTypeEnum {
  CHECKING_ACCOUNT = 'CHECKING_ACCOUNT',
  CREDIT_CARD = 'CREDIT_CARD',
  EXTERNAL_CREDIT_CARD = 'EXTERNAL_CREDIT_CARD',
}

export const paymentMethodLabelDictionary: Record<PaymentMethodTypeEnum, string> = {
  [PaymentMethodTypeEnum.CHECKING_ACCOUNT]: 'Débito',
  [PaymentMethodTypeEnum.CREDIT_CARD]: 'Crédito',
  [PaymentMethodTypeEnum.EXTERNAL_CREDIT_CARD]: 'Outros cartões',
}
