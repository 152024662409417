import { Separator } from 'components'
import styled from 'styled-components'

import { uuid } from '@interco/inter-ui'
import { Flex } from '@interco/inter-ui/components/Flex'
import { Skeleton } from '@interco/inter-ui/components/Skeleton'

const ShimmerContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const ShimmerClinics = () => (
  <ShimmerContainer>
    {Array.from({ length: 5 }).map(() => (
      <div key={uuid()}>
        <Flex direction="column" gap="8px">
          <Skeleton width="80%" height="24px" />
          <Skeleton width="30%" height="16px" />
          <Skeleton width="60%" height="16px" />
          <Skeleton width="40%" height="16px" />
        </Flex>

        <Separator borderWidth="1px" type="dashed" color="var(--gray200)" margin="16px 0" />
      </div>
    ))}
  </ShimmerContainer>
)
