import AccreditedService from 'services/AccreditedService'
import useBoundState from 'store'
import { errorHandling, redirectRoute } from 'store/utils/provider'
import { StateCreator } from 'zustand'

import { CoveredClinicsState, ICoveredClinicsRequest } from './types'

export const createCoveredClinicsSlice: StateCreator<
  CoveredClinicsState,
  [],
  [],
  CoveredClinicsState
> = (set) => ({
  clinics: [],

  setCoveredClinics: (clinics) => set(() => ({ clinics })),

  getCoveredClinics: async (payload) => {
    useBoundState.getState().setIsLoading(true)

    const { ibgeCode } = payload as ICoveredClinicsRequest

    await AccreditedService.getCoveredClinics(ibgeCode)
      .then((response) => {
        set({ clinics: [...response.clinics] })

        redirectRoute(payload)
      })

      .catch((error) => {
        errorHandling(error as Error, 'AccreditedService.getCoveredClinics')
      })

    useBoundState.getState().setIsLoading(false)
  },
})
