import BaseBridge from 'config/bridge/BaseBridge'
import BaseService from 'config/bridge/BaseService'
import { ICreateSubscriptionResponse, IPetBreed, ISubscription } from 'store/subscription/types'
import { IPet } from 'types/IRegister'

export default class SubscriptionService {
  static async getSubscription(ibgeCode?: string) {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        ibgeCode,
        'x-inter-user-bank-account': (await BaseBridge.getUserInfo()).account,
      }
    } else {
      headers = {
        ibgeCode,
      }
    }

    const response = await BaseService.doExecute<ISubscription>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_PET}/landingPage`,
      headers,
    })

    return response.data
  }

  static async getPetBreeds(petType: string) {
    const response = await BaseService.doExecute<IPetBreed[]>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_PET}/pets/breeds/${petType}`,
    })

    return response.data
  }

  static async postSubscription(pet: IPet) {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        'x-inter-user-bank-account': (await BaseBridge.getUserInfo()).account,
      }
    }

    const body = {
      petName: pet.name,
      petType: pet.type,
      ibgeCode: pet.ibgeCode,
      location: pet.location,
      birthday: pet.birthday,
      gender: pet.gender,
      breedId: pet.breedId,
    }

    const { account } = await BaseBridge.getUserInfo()

    BaseBridge.requestAnalytics('PET_FRONT', {
      account,
      animal_type: pet.type,
      pet_name: pet.name,
      ibge_code: pet.ibgeCode,
      location: pet.location,
      birthday: pet.birthday,
      gender: pet.gender,
      breed: pet.breed,
      breedId: `${pet.breedId}`,
    })

    const response = await BaseService.doExecute<ICreateSubscriptionResponse>({
      method: 'POST',
      endpoint: `${process.env.REACT_APP_API_PET}/pets`,
      headers,
      data: body,
    })

    BaseBridge.requestAnalytics('PET_FRONT', {
      account,
      response_post_subscription: JSON.stringify(response.data),
    })

    return response
  }
}
