import { Icons } from 'utils/icons'

import { Accordion } from '@interco/inter-ui/components/Accordion'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './AccordionCustom.styles'
import { IAccordionCustomProps } from './AccordionCustom.types'

export const AccordionCustom = ({
  icon,
  title,
  variantTitle,
  expanded,
  children,
  onClick,
}: PropsWithRequiredChildren<IAccordionCustomProps>) => {
  const accordionIcons = {
    down: <Icons.ChevronDown width={24} height={24} color="var(--primary500)" />,
  }

  return (
    <S.AccordionContent>
      <Accordion
        labelComponent={
          <S.Header data-testid="test-accordion" expanded={expanded}>
            {icon}
            <Text variant={variantTitle || 'caption-1'} bold colorWeight={500}>
              {title}
            </Text>
            {accordionIcons.down}
          </S.Header>
        }
        expanded={expanded}
        onClick={onClick}
      >
        {children}
      </Accordion>
    </S.AccordionContent>
  )
}
