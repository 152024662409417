import BaseBridge from 'config/bridge/BaseBridge'
import { TagEventsEnum } from 'enums/TagEvents'

export const AnalyticsLocation = (name: string, params: Record<string, string>) => {
  BaseBridge.requestAnalytics(name, {
    ...params,
    ref_figma: '9',
    ref_type: TagEventsEnum.REF_BUTTON,
    screen: 'Cobertura',
    content_action: TagEventsEnum.ACTION_CLICK,
    action_id: 'Selecionar cidade',
  })
}
