export enum PetTypeEnum {
  DOG = 'DOG',
  CAT = 'CAT',
  NONE = 'NONE',
}

export const petLabelDictionary: Record<PetTypeEnum, string> = {
  [PetTypeEnum.DOG]: 'Cachorro',
  [PetTypeEnum.CAT]: 'Gato',
  [PetTypeEnum.NONE]: '',
}
