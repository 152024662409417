import { useCallback, useEffect } from 'react'

import { ActionIconEnum } from 'enums/ActionIcon'
import { PageTitles } from 'enums/PageTitles'
import { useNavBar } from 'hooks/useNavBar'
import { useHistory } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { PathQueryParams } from 'utils/pathQueryParams'

import { ShimmerHome } from '../Home/ShimmerHome/ShimmerHome'

export const Start = () => {
  const history = useHistory()
  const handlePathQueryParams = PathQueryParams()

  const getLandingPage = useBoundState((state) => state.getLandingPage)
  const getAfterSales = useBoundState((state) => state.getAfterSales)
  const setFirstPage = useBoundState((state) => state.setFirstPage)
  useNavBar({
    navbarTitle: PageTitles.PET,
    actionIcons: {
      leftIcon: ActionIconEnum.BACK,
    },
  })

  const handleAfterSales = useCallback(() => {
    setFirstPage(TypesRoutes.AFTER_SALES)
    history.push(TypesRoutes.AFTER_SALES)
  }, [history, setFirstPage])

  const handleLandingPage = useCallback(() => {
    setFirstPage(TypesRoutes.HOME)

    getLandingPage({
      history,
      pathname: TypesRoutes.HOME,
    })
  }, [getLandingPage, history, setFirstPage])

  const openApplication = useCallback(() => {
    getAfterSales({
      onTransmission: (hasNotOnlyCanceledPets) => {
        hasNotOnlyCanceledPets ? handleAfterSales() : handleLandingPage()
      },
    })
  }, [getAfterSales, handleAfterSales, handleLandingPage])

  useEffect(() => {
    if (!handlePathQueryParams()) {
      openApplication()
    }
    // Deixar o array de dependência vazio, senão vai renderizar várias vezes
  }, [])

  return <ShimmerHome />
}
