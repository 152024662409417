import { Separator } from 'components'

import { uuid } from '@interco/inter-ui'
import { Flex } from '@interco/inter-ui/components/Flex'
import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import { ShimmerContainer } from './ShimmerFAQ.styles'

export const ShimmerFAQ = () => (
  <ShimmerContainer>
    <Skeleton width="100%" height="48px" style={{ marginBottom: '20px' }} />

    {Array.from({ length: 4 }).map(() => (
      <div key={uuid()}>
        <Flex direction="row" alignItems="center" style={{ padding: '16px' }}>
          <Skeleton width="72%" height="16px" />
          <Skeleton width="24px" height="24px" />
        </Flex>

        <Separator borderWidth="1px" />

        <Flex direction="row" alignItems="center" style={{ padding: '16px' }}>
          <div style={{ width: '80%' }}>
            <Skeleton width="100%" height="16px" />
            <Skeleton width="50%" height="16px" />
          </div>
          <Skeleton width="24px" height="24px" />
        </Flex>

        <Separator borderWidth="1px" />
      </div>
    ))}

    <Flex direction="row" alignItems="center" justifyContent="center" style={{ padding: '16px 0' }}>
      <Skeleton width="48%" height="16px" />
    </Flex>
  </ShimmerContainer>
)
