import { Fragment, useEffect, useState } from 'react'

import { ListDescription, LogoPetlove, Separator } from 'components'
import BaseBridge from 'config/bridge/BaseBridge'
import { ActionIconEnum } from 'enums/ActionIcon'
import { EligibilityStatusEnum } from 'enums/EligibilityStatus'
import { PageTitles } from 'enums/PageTitles'
import { TagEventsEnum } from 'enums/TagEvents'
import { useLocalErrorHandler } from 'hooks/useLocalErrorHandler'
import { useNavBar } from 'hooks/useNavBar'
import { useHistory } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { InfoSign } from 'styles/commons'
import { IBottomSheetConfig, ICoparticipation, IHowWorks } from 'types/IHome'
import { Icons } from 'utils/icons'

import { uuid } from '@interco/inter-ui'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import { Flex } from '@interco/inter-ui/components/Flex'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './Home.styles'
import { AnalyticsHomeClick, AnalyticsHomeOpen } from './Home.tags'
import { Plans } from './Plans/Plans'

export const Home = () => {
  const history = useHistory()
  const handlerError = useLocalErrorHandler()

  const isLoading = useBoundState((state) => state.isLoading)
  const clinics = useBoundState((state) => state.clinics)
  const eligibility = useBoundState((state) => state.eligibility)
  const { header, location, coparticipation, plans } = useBoundState((state) => state.subscription)
  const setCurrentLocation = useBoundState((state) => state.setCurrentLocation)
  const getCoveredClinics = useBoundState((state) => state.getCoveredClinics)

  const [bottomSheet, setBottomSheet] = useState(false)
  const [bottomSheetConfig, setBottomSheetConfig] = useState<IBottomSheetConfig | null>(null)

  const icons = {
    right: <Icons.ChevronRight color="var(--primary500)" />,
    location: <Icons.Location color="var(--primary500)" />,
    moneyCheck: <Icons.MoneyCheck width={24} height={24} color="var(--gray500)" />,
    question: <Icons.Help width={24} height={24} color="var(--primary500)" />,
    listCheck: <Icons.ListCheck width={24} height={24} color="var(--gray500)" />,
    schedule: <Icons.Schedule width={24} height={24} color="var(--gray500)" />,
    check: <Icons.Check width={16} height={16} color="var(--primary500)" />,
    warning: <Icons.Warning width={32} height={32} color="var(--alert500)" />,
  }

  useNavBar({
    navbarTitle: PageTitles.PET,
    actionIcons: {
      leftIcon: ActionIconEnum.BACK,
      rightIcon: ActionIconEnum.FAQ,
    },
  })

  useEffect(() => {
    AnalyticsHomeOpen(TagEventsEnum.PET_FLOW)
  }, [])

  const coparticipationList: ICoparticipation[] = [
    {
      icon: icons.moneyCheck,
      title: 'Pague somente pelos serviços que usar',
      subtitle: 'Você paga uma mensalidade mais barata e uma taxa quando o pet utilizar o plano.',
    },
    {
      icon: icons.listCheck,
      title: 'Valores de coparticipação que cabem no bolso',
      subtitle:
        'Vacinas e consultas simples a partir de R$ 20,00 e exames veterinários a partir de R$ 10,00.',
      route: 'Mostrar valores de coparticipação',
    },
    {
      icon: icons.schedule,
      title: 'Plano ativo ao final da contratação',
      subtitle: 'O prazo de carência começa a contar ao final da contratação do plano.',
      route: 'Conhecer prazos de carência',
    },
  ]

  const howWorksList: IHowWorks[] = [
    {
      label: 'Você contrata o plano de forma 100% digital, pelo app do Inter.',
    },
    {
      label:
        'Ao final da contratação, o plano, oferecido pela PetLove, já estará ativo e a carência começará a contar.',
    },
    {
      label: 'Encontre a clínica mais perto de você e comece a usar.',
    },
  ]

  const handleClickOpenPdf = async (route: string | undefined) => {
    if (!route) return

    const isCoparticipation = route.includes('coparticipação')

    AnalyticsHomeClick(TagEventsEnum.PET_FLOW, {
      ref_figma: isCoparticipation ? '3' : '4',
      action_id: isCoparticipation ? 'Valores coparticipação' : 'Prazos carência',
    })

    try {
      await BaseBridge.openPdf(
        isCoparticipation
          ? coparticipation.urlPdfCoparticipation
          : coparticipation.urlPdfGracePeriods,
        'Coparticipação e carência',
      )
    } catch (error) {
      handlerError(error as Error, 'Home.openPdf')
    }
  }

  const fetchCoveredClinicsResponse = () => {
    AnalyticsHomeClick(TagEventsEnum.PET_FLOW, {
      ref_figma: '6',
      action_id: 'Rede Credenciada',
    })

    if (clinics.length <= 0) {
      getCoveredClinics({
        ibgeCode: location.ibgeCode,
        history,
        pathname: TypesRoutes.PARTNER_CLINICS,
      })

      setCurrentLocation({
        city: location.city,
        ibgeCode: location.ibgeCode,
      })

      return
    }

    history.push(TypesRoutes.PARTNER_CLINICS)
  }

  const handleClickSimulate = () => {
    AnalyticsHomeClick(TagEventsEnum.PET_FLOW, {
      ref_figma: '8',
      action_id: 'Contratar agora',
      location: location.city,
    })

    const bottomSheetConfigs: Record<EligibilityStatusEnum, IBottomSheetConfig | undefined> = {
      [EligibilityStatusEnum.NON_ELIGIBLE_PJ]: {
        title: 'Temos outros seguros para você',
        description:
          'O Plano de Saúde Pet não está disponível no momento. Confira outros produtos do Inter para você.',
      },
      [EligibilityStatusEnum.NON_ELIGIBLE_UNDER_AGE]: {
        title: 'Que tal pedir ao titular da conta?',
        description:
          'O Plano Pet não está disponível para menores de 18 anos, mas pode ser contratado pela pessoa responsável por sua conta.',
      },
      [EligibilityStatusEnum.ELIGIBLE]: undefined,
    }

    const config = bottomSheetConfigs[eligibility]

    if (config) {
      setBottomSheet(true)
      setBottomSheetConfig(config)
      return
    }

    history.push(TypesRoutes.REGISTER)
  }

  const handleClickChangeLocation = () => {
    AnalyticsHomeClick(TagEventsEnum.PET_FLOW, {
      ref_figma: '2',
      action_id: 'Mudar localização',
    })

    history.push(TypesRoutes.LOCATION)
  }

  const renderLocation = (
    <S.Location>
      <Text variant="headline-h2" as="h2" semiBold>
        Você esta contratando o seu plano para:
      </Text>
      <ListDescription
        data-testid="btn-location"
        iconLeft={icons.location}
        iconRight={icons.right}
        withBorder
        margin="16px 0"
        onClick={handleClickChangeLocation}
        isLoading={isLoading}
      >
        <Text variant="body-3" color="primary" bold colorWeight={500}>
          {location.city}
        </Text>
      </ListDescription>
      <Text variant="body-3">Para alterar a sua cidade, clique no botão acima.</Text>
    </S.Location>
  )

  const renderCoparticipation = (
    <S.Coparticipation>
      <Text variant="headline-h2" as="h2" semiBold>
        Entenda a coparticipação e a carência do plano
      </Text>

      {coparticipationList.map((item, index) => (
        <Fragment key={uuid()}>
          <Flex direction="row" gap="16px" justifyContent="normal">
            <div>{item.icon}</div>

            <div>
              <Text variant="body-3" bold colorWeight={500}>
                {item.title}
              </Text>
              <Text variant="body-3">{item.subtitle}</Text>
              {item.route && (
                <Button variant="link" onClick={() => handleClickOpenPdf(item.route)}>
                  {item.route}
                </Button>
              )}
            </div>
          </Flex>

          {index !== coparticipationList.length - 1 && (
            <Separator borderWidth="1px" type="dashed" color="var(--gray200)" margin="16px 0" />
          )}
        </Fragment>
      ))}
    </S.Coparticipation>
  )

  const renderPartnerClinics = (
    <S.PartnerClinics>
      <Text variant="headline-h2" as="h2" semiBold>
        Encontre as clínicas parceiras mais perto de você
      </Text>
      <Text variant="body-3">
        São mais de 600 clínicas cadastradas nas regiões atendidas pela Petlove.
      </Text>
      <ListDescription
        data-testid="btn-partner-clinics"
        iconLeft={icons.location}
        iconRight={icons.right}
        withBorder
        margin="24px 0 16px"
        onClick={fetchCoveredClinicsResponse}
        isLoading={isLoading}
      >
        <Text variant="body-3" color="primary" colorWeight={500} bold>
          Rede Credenciada
        </Text>
      </ListDescription>
    </S.PartnerClinics>
  )

  const renderHowWorks = (
    <S.Works>
      <Text variant="headline-h2" as="h2" semiBold>
        Como funciona?
      </Text>

      {howWorksList.map((how) => (
        <Flex
          key={uuid()}
          direction="row"
          gap="16px"
          justifyContent="normal"
          style={{ marginBottom: '16px' }}
        >
          <div>{icons.check}</div>

          <Text variant="body-3">{how.label}</Text>
        </Flex>
      ))}

      <ListDescription
        data-testid="btn-faq"
        iconLeft={icons.question}
        iconRight={icons.right}
        withBorder
        onClick={() => history.push(TypesRoutes.FAQ)}
      >
        <Text variant="body-3" color="primary" bold colorWeight={500}>
          Perguntas frequentes
        </Text>
      </ListDescription>
    </S.Works>
  )

  const renderStickFooter = (
    <S.Footer>
      <Button fullWidth variant="primary" disabled={isLoading} onClick={handleClickSimulate}>
        Contratar agora
      </Button>
    </S.Footer>
  )

  const renderBottomSheet = bottomSheet && bottomSheetConfig && (
    <BottomSheet noSwipe onClose={() => setBottomSheet(false)}>
      <S.BottomSheetContent>
        <InfoSign>{icons.warning}</InfoSign>
        <Text variant="headline-h3" as="h3" semiBold>
          {bottomSheetConfig.title}
        </Text>
        <Text variant="body-3">{bottomSheetConfig.description}</Text>
        <Button fullWidth variant="primary" onClick={() => setBottomSheet(false)}>
          Entendi
        </Button>
      </S.BottomSheetContent>
    </BottomSheet>
  )

  return (
    <>
      <S.Container>
        <S.Hero>
          <img src={header.urlImage} alt="Cachorro e gato sentados na grama" />
        </S.Hero>

        <S.Header data-testid="home-header">
          <Text variant="headline-h1" as="h1" semiBold>
            Simplifique os cuidados com a saúde do seu pet
          </Text>
          <Text variant="body-3">
            Atendimento para cães e gatos em clínicas veterinárias com acesso a vacinas, exames
            laboratoriais e consultas para o seu pet.
          </Text>
        </S.Header>

        {renderLocation}

        <Plans plans={plans} />

        <Separator margin="40px 0 32px" />

        {renderCoparticipation}

        <Separator margin="32px 0" />

        {renderPartnerClinics}

        <Separator margin="32px 0" />

        {renderHowWorks}

        <Separator margin="32px 0" />

        <LogoPetlove />
      </S.Container>

      {renderStickFooter}

      {renderBottomSheet}
    </>
  )
}
