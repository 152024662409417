import styled from 'styled-components'

export const ShimmerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
  overflow: hidden;
`

export const ShimmerHeader = styled.figure`
  img {
    width: 100%;
    height: auto;
  }
`
export const ShimmerBody = styled.figure`
  padding: 16px 24px;
`
