import { AfterSalesStatusEnum } from 'enums/AfterSalesStatus'
import { defaultError } from 'mocks/ResponseError'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import AccreditedService from 'services/AccreditedService'
import AfterSalesService from 'services/AfterSalesService'
import useBoundState from 'store'
import { errorHandling, redirectRoute } from 'store/utils/provider'
import { StateCreator } from 'zustand'

import { AfterSalesState, IAfterSalesRequest, IAfterSalesResponse, IPetAfterSales } from './types'

export const createAfterSalesSlice: StateCreator<AfterSalesState, [], [], AfterSalesState> = (
  set,
) => ({
  afterSales: {} as IAfterSalesResponse,
  afterSalesPet: {} as IPetAfterSales,

  setAfterSales: (afterSales) => set(() => ({ afterSales })),

  getAfterSales: async (payload) => {
    useBoundState.getState().setIsLoading(true)

    const { onTransmission } = payload as IAfterSalesRequest

    await AfterSalesService.getAfterSales()
      .then((response) => {
        set({ afterSales: { ...response } })

        if (onTransmission) {
          const filteredPets = response.pets.filter(
            (pet) =>
              pet.status === AfterSalesStatusEnum.ACTIVE ||
              pet.status === AfterSalesStatusEnum.PARTNER_IN_PROCESS ||
              pet.status === AfterSalesStatusEnum.SUSPENDED,
          )

          onTransmission(filteredPets.length > 0)
        }
      })

      .catch((error) => {
        errorHandling(error as Error, 'AfterSalesService.getAfterSales', {
          ...defaultError,
          route: TypesRoutes.START,
        })
      })

    useBoundState.getState().setIsLoading(false)
  },

  setAfterSalesPet: (afterSalesPet) => set(() => ({ afterSalesPet })),

  getCoveredClinicsAfterSales: async (payload) => {
    useBoundState.getState().setIsLoading(true)

    const coveredCitiesPromise = AccreditedService.getCoveredCities()
    const coveredClinicsPromise = AccreditedService.getCoveredClinics()

    try {
      const [coveredClinicsResponse, coveredCitiesResponse] = await Promise.all([
        coveredClinicsPromise,
        coveredCitiesPromise,
      ])

      useBoundState.setState({
        locations: [...coveredCitiesResponse.locations],
        clinics: [...coveredClinicsResponse.clinics],
        currentLocation: {
          ibgeCode: coveredClinicsResponse.ibgeCode,
          city: coveredClinicsResponse.city,
          uf: coveredClinicsResponse.uf,
        },
      })

      if (payload) {
        redirectRoute(payload)
      }
    } catch (error) {
      errorHandling(error as Error, 'AfterSalesService.getCoveredClinicsAfterSales', {
        ...defaultError,
        route: TypesRoutes.START,
      })
    } finally {
      useBoundState.getState().setIsLoading(false)
    }
  },
})
