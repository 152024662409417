import styled from 'styled-components'

export const Container = styled.div`
  height: calc(100vh - 54px);
  overflow: scroll;
`

const defaultDiv = styled.div`
  padding: 0 24px;

  button {
    padding-left: 0px;
  }
`

export const MyPets = styled.div`
  padding: 0 24px;
`

export const QuickAccess = styled(defaultDiv)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  span {
    display: block;
  }

  button {
    display: block;
    max-width: 64px;
    max-height: 100%;
    padding: 0;
    text-align: center;

    &:hover:enabled {
      background-color: transparent;
    }
  }
`

export const QuickAccessButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin: 0 auto 8px;
  background-color: var(--primary100);
  border-radius: 100%;
`

export const Footer = styled(defaultDiv)`
  margin-bottom: 24px;

  button {
    width: 100%;
    margin-bottom: 16px;
  }
`
