import { Separator } from 'components'
import styled from 'styled-components'

import { uuid } from '@interco/inter-ui'
import { Flex } from '@interco/inter-ui/components/Flex'
import { Skeleton } from '@interco/inter-ui/components/Skeleton'

const ShimmerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
  padding: 24px;
  overflow: hidden;
`

export const ShimmerReceipt = () => (
  <ShimmerContainer>
    <Flex direction="column">
      <Skeleton width="64px" height="64px" variant="circle" style={{ margin: '0 auto' }} />
      <Skeleton width="100%" height="48px" style={{ margin: '24px 0px 8px' }} />
      <Skeleton width="64%" height="48px" />
    </Flex>

    <Separator borderWidth="1px" margin="24px 0" />

    <Flex direction="row" gap="16px" alignItems="flex-start">
      <Skeleton width="24px" height="24px" />
      <div style={{ flex: '1' }}>
        <Skeleton width="64%" height="16px" style={{ marginBottom: '4px' }} />
        <Skeleton width="100%" height="56px" />
      </div>
    </Flex>

    <Separator borderWidth="8px" margin="32px 0" />

    <Flex direction="column" alignItems="center">
      <Skeleton width="24%" height="16px" style={{ marginBottom: '4px' }} />
      <Skeleton width="32%" height="32px" />
    </Flex>

    <Separator borderWidth="1px" margin="24px 0" />

    <Flex direction="column">
      <Skeleton width="32%" height="16px" style={{ marginBottom: '16px' }} />
      <Skeleton width="80%" height="32px" />
    </Flex>

    {Array.from({ length: 5 }).map(() => (
      <div key={uuid()}>
        <Flex direction="row" alignItems="center" style={{ marginTop: '16px' }}>
          <Skeleton width="16%" height="16px" />
          <Skeleton width="32%" height="16px" />
        </Flex>
      </div>
    ))}
  </ShimmerContainer>
)
