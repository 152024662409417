import { PetTypeEnum, petLabelDictionary } from 'enums/PetType'
import { IPetProps } from 'types/IRegister'

import { Flex } from '@interco/inter-ui/components/Flex'
import { Grid } from '@interco/inter-ui/components/Grid'
import { Radio } from '@interco/inter-ui/components/Radio'
import { Text } from '@interco/inter-ui/components/Text'

import imgCat from '../../../../assets/img/cat.png'
import imgDog from '../../../../assets/img/dog.png'
import * as S from './Pet.styles'

export const Pets = ({ pets, selectedItem, onChange }: IPetProps) => {
  const renderImgDog = <img src={imgDog} alt="Imagem mostra um cachorro" />
  const renderImgCat = <img src={imgCat} alt="Imagem mostra um gato" />

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap="16px">
      {pets.map((pet) => (
        <S.Choose
          key={pet}
          htmlFor={pet}
          className={selectedItem === pet ? 'selected' : ''}
          onChange={() => onChange(pet)}
        >
          {pet === PetTypeEnum.DOG ? renderImgDog : renderImgCat}

          <Flex direction="row" alignItems="center">
            <Text variant="body-3" bold colorWeight={500}>
              {petLabelDictionary[pet]}
            </Text>

            <Radio id={pet} name="pet" value={pet} checked={selectedItem === pet} />
          </Flex>
        </S.Choose>
      ))}
    </Grid>
  )
}
