import styled, { keyframes } from 'styled-components'

export const ANIMATION_TIME = 1000

export const openSheetAnimation = keyframes`
  0% {
    background: transparent;
  }

  100% {
    background: var(--bottom-sheet-overlay-background-color);
  }
`

const swipeUp = keyframes`
  0% {
    transform: scale(.8) translateY(1000px);
    opacity: 0;
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
`

export const Container = styled.div`
  .react-datepicker__tab-loop {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: var(--bottom-sheet-overlay-z-index);
    background: var(--bottom-sheet-overlay-background-color);
    transition: 0.5s;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    animation: ${openSheetAnimation} ${ANIMATION_TIME}ms;
  }

  .react-datepicker-popper {
    position: fixed;
    width: 100%;
    padding: 0;
    transform: translate3d(0, 0, 0) !important;

    .react-datepicker {
      border-top-left-radius: var(--radiiLg);
      border-top-right-radius: var(--radiiLg);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 16px;
      animation: ${swipeUp} ${ANIMATION_TIME}ms cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
  }

  .react-datepicker__header {
    span {
      margin: 0 5px 0 0;
    }
  }
`
