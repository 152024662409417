import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin-bottom: 16px;

  span {
    display: block;
    text-align: center;
    margin-bottom: 8px;
  }
`

export const LogoImage = styled.div`
  display: block;
  margin: auto;
  width: 95px;

  img {
    width: 100%;
    height: auto;
  }
`
