import BaseBridge from 'config/bridge/BaseBridge'
import { TagEventsEnum } from 'enums/TagEvents'

export const AnalyticsHowUse = (name: string) => {
  BaseBridge.requestAnalytics(name, {
    ref_figma: '20',
    screen: 'Como utilizar o plano pet?',
    ref_type: TagEventsEnum.REF_SCREEN,
    content_action: TagEventsEnum.ACTION_LOAD_DATA,
    action_id: 'Acessou a tela de como utilizar o plano pet',
  })
}
