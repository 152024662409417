import styled from 'styled-components'

export const AccordionSection = styled.div`
  padding: 0 24px;

  > :first-child {
    padding-top: 0;
  }

  > :last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }

  div {
    padding-left: 0px;
    padding-right: 0px;
  }

  span {
    display: block;
  }

  button {
    margin-top: 16px;
    padding: 0;
    font-size: 12px;
    background-color: transparent;
  }

  .contactInfo {
    color: var(--primary500);
    margin: 8px 0;
  }
`

export const Weekdays = styled.div`
  margin-top: 16px;
`
