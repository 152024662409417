import styled from 'styled-components'

export const AccordionSection = styled.div`
  button {
    margin-top: 16px;
    padding: 0;
  }
`

export const AccordionList = styled.div`
  li {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }
`
