import { subtractYearsFromDate } from 'utils/date'

import { DatePicker } from '@interco/inter-ui/components/DatePicker'

import { Container } from './Calendar.styles'

interface CalendarProps {
  date: Date | undefined
  onChange: (value: Date) => void
}

export const Calendar = ({ date, onChange }: CalendarProps) => {
  const todayMinus30Years = subtractYearsFromDate(new Date(), 50)

  return (
    <Container>
      <DatePicker
        value={date}
        placeholder="dd/mm/aaaa"
        className="datePicker"
        minDate={todayMinus30Years}
        maxDate={new Date()}
        onChange={onChange}
        popperPlacement="top-end"
      />
    </Container>
  )
}
