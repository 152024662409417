import NewRelicUtils from 'config/monitoring/NewRelicUtils'
import { History } from 'history'
import { defaultError } from 'mocks/ResponseError'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { ErrorDetails } from 'store/error/types'

export interface WithHistory {
  history?: History
  pathname?: string
  goBack?: boolean
}

/**
 * Realiza o redirecionamento utilizando o useHistory recebido por parâmetro.
 * @param payload Payload recebido através da action.
 */
export function redirectRoute(payload: WithHistory): void {
  if (payload && payload.history && (payload.pathname || payload.goBack)) {
    const { history } = payload

    if (payload.goBack) {
      history.goBack()
    } else if (payload?.pathname) {
      history.push(payload.pathname)
    }
  }
}

/**
 * Tratativa de erro a ser realizada no Zustand.
 */
export function errorHandling(
  error: Error,
  errorCodeRef: string,
  errorDetails?: ErrorDetails,
  restart?: boolean,
) {
  NewRelicUtils.noticeError(error, { errorCodeRef })

  if (errorDetails === undefined) {
    errorDetails = {
      ...defaultError,
      route: restart ? TypesRoutes.START : undefined,
    }
  }

  useBoundState.getState().setHasError(errorDetails)
  useBoundState.getState().setIsLoading(false)
}
